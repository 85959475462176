<template>
  <div class="container">
    <SlideShow></SlideShow>
    <ImportanceMessage style="margin-top:50px;"></ImportanceMessage>
    <MartyrShow  style="margin-top:80px; margin-bottom:30px;"></MartyrShow>
    <!-- <VRLink></VRLink> -->
    <BottomShow ></BottomShow>
  </div>
</template>
<script>
import BottomShow from '@/components/BottomShow.vue'
import MartyrShow from '@/components/MartyrShow.vue'
import ImportanceMessage from '@/components/ImportanceMessage.vue'
import SlideShow from '@/components/SlideShow.vue'
import VRLink from '@/components/VRLink.vue'
export default {
    components: {
    SlideShow,
    ImportanceMessage,
    MartyrShow,
    VRLink,
    BottomShow
  },
}
</script>

<style scoped>
.container{
  max-width:1440px;

}
</style>