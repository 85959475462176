<template>
    <div class="outside">
        <div class="container">
            <nonlocalVenue></nonlocalVenue>
            <foreignVenue></foreignVenue>
        </div>
    </div>
</template>
<script>
import nonlocalVenue from '../components/nonlocalVenue.vue'
import foreignVenue from '../components/foreignVenue.vue'
export default {
    components:{
        nonlocalVenue,
        foreignVenue
    }
}
</script>

<style scoped>
.outside{
    width:1440px;
}
</style>