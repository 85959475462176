<template>
    <div class="container">
        <div class="in_container">
            <el-tabs v-model="activeName"  class="main_tab">
            <el-tab-pane label="资讯速览" >
                <div class="main_message">
                    <div class="message_picture" style="height: 343px;width:625px;">
                        <el-carousel style="height: 343px;">
                            <el-carousel-item v-for="item in SilderItems" :key="item.id" style="height: 343px;width:625px;">
                                <div class="overlay" @click="showArticle(item.id)">
                                    <span class="overlay_title">{{ item.title }}</span>
                                </div>
                                <div>
                                    <img :src=item.thumbnail style="height: 343px;width:625px;">
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div >
                        <el-tabs v-model="selectName" class="inner_tab">
                            <el-tab-pane label="重要时事" name="first">
                                <ul>
                                    <li v-for="item in NewsItems" :key="item.id" @click="showArticle(item.id)">
                                        <div class="show_message">
                                            <div class="custom-card-header">
                                                <div class="custom-card-detail">
                                                    <el-tooltip :content="item.title" placement="top-start" effect="light">
                                                        <div calss="detail-title" @click="showArticle(item.id)">
                                                                 {{ item.title }}
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                                <div>
                                                    <span style="font-size:16px">{{ item.updateTime}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </el-tab-pane>
                            <el-tab-pane label="活动要闻" name="second">
                                <ul>
                                    <li v-for="item in ActivityItems" :key="item.id" @click="showArticle(item.id)">
                                        <div class="show_message">
                                            <div class="custom-card-header">
                                                <div class="custom-card-detail">
                                                    <el-tooltip :content="item.title" placement="top-start" effect="light">
                                                        <div calss="detail-title">
                                                            {{ item.title }}
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                                <div>
                                                    <span style="font-size:16px">{{ item.updateTime}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </el-tab-pane>
            </el-tabs>
            <span class="more" @click="viewMore()" >>>>更多</span>
        </div>
    </div>
</template>
<script>
import service from '../axios/axios'
    export default {
      data() {
        return {
            activeName:'0',
            selectName:'first',
            SilderItems:[
            { id: 2, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            { id: 3, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"02-19" },
            { id: 4, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-14"},
            { id: 5, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            ],
            ActivityItems:[
            { id: 1, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            { id: 2, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"02-19" },
            { id: 3, top:1,title: '标题' ,summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-14"},
            { id: 4, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            { id: 5, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"02-19" },
            { id: 6, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },

          ],
          NewsItems:[
            { id: 1, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            { id: 2, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"02-19" },
            { id: 3, top:1,title: '标题' ,summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-14"},
            { id: 4, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },
            { id: 5, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"02-19" },
            { id: 6, top:1,title: '标题',summary:"内容",thumbnail:require("../img/烈士信息背景图.jpeg"),updateTime:"05-17" },

          ],
        };
      },
      methods:{
        viewMore(){
            this.$router.push('/Information/news');
            this.$bus.$emit('activeIndex','/Information/news');
        },
        showArticle(val){
            this.$router.push({path:'/ArticleDetail',query:{articleId:val,type:1}});
        },
        getActivityList(){
            service.get('/activity/list',{
                params:{
                    pageNum:1,
                    pageSize:6
                }
            })
            .then(response => {
                this.ActivityItems=[];
                this.SilderItems=[];
                for(var i=0;i<response.data.data.total;i++){
                        if(this.ActivityItems.length===6)break;
                        if(this.SilderItems.length<2){
                                const item={
                                    id:Number(response.data.data.rows[i].id),
                                    summary:response.data.data.rows[i].summary,
                                    thumbnail:response.data.data.rows[i].thumbnail,
                                    title:response.data.data.rows[i].title,
                                    updateTime:response.data.data.rows[i].updateTime
                                }
                                this.SilderItems.push(item);
                        }
                        let obj={
                            id:Number(response.data.data.rows[i].id),
                            summary:response.data.data.rows[i].summary,
                            thumbnail:response.data.data.rows[i].thumbnail,
                            title:response.data.data.rows[i].title,
                            updateTime:response.data.data.rows[i].updateTime
                        }
                        this. ActivityItems.push(obj);
                }
            })
            .catch(error => {
                this.$message.error('错了哦，这是一条错误消息');
        })
        },
        getNewsList(){
            service.get('/news/list',{
                params:{
                    pageNum:1,
                    pageSize:6
                }
            })
            .then(response => {
                this.NewsItems=[];
                for(var i=0;i<response.data.data.total;i++){
                    if(this.NewsItems.length===6)break;
                    if(this.SilderItems.length<4){
                        const item={
                            id:Number(response.data.data.rows[i].id),
                            summary:response.data.data.rows[i].summary,
                            thumbnail:response.data.data.rows[i].thumbnail,
                            title:response.data.data.rows[i].title,
                            updateTime:response.data.data.rows[i].updateTime
                        }
                        this.SilderItems.push(item);
                    }
                    let obj={
                        id:Number(response.data.data.rows[i].id),
                        summary:response.data.data.rows[i].summary,
                        thumbnail:response.data.data.rows[i].thumbnail,
                        title:response.data.data.rows[i].title,
                        updateTime:response.data.data.rows[i].updateTime
                    }
                    this. NewsItems.push(obj);
                }
            })
            .catch(error => {
                this.$message.error('错了哦，这是一条错误消息');
        })
        }
    },
      mounted(){
        this.getActivityList();
        this.getNewsList();
      }
    };
</script>

<style scoped>

.in_container{
    margin:0 40px;
}
ul {
    list-style: none;
}
ul li {
    cursor: pointer;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.502);
    position: relative; /* 确保 ::marker 伪元素的绝对定位相对于列表项 */
    padding-left: 2px;
}
ul li:hover {
    cursor: pointer;
    font-size: 16px;
    color: rgba(236, 14, 14, 0.934);
    position: relative; /* 确保 ::marker 伪元素的绝对定位相对于列表项 */
    padding-left: 10px;
}
ul li::marker {
    content: "•";
    color: rgb(159, 58, 58);
    font-size: 28.8px;
    position: absolute;
    left: 0;
}
.show_message{
    margin: 5px;
}
.main_message{
    margin-top:20px;
    height:343px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.custom-card {
    cursor: pointer;
    margin: 14.4px;
    height: 86.4px;
}
.custom-card-header{
    margin:4.32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.custom-card-detail{
    /* display: flex;
    flex-direction: column; */
    width:420px;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden;   /* 隐藏超出的内容 */
    text-overflow: ellipsis; /* 超出的部分用省略号表示 */
}
div >>>.main_tab .el-tabs__item.is-active{
  font-size:28.8px;
  font-family:'黑体';
  font-weight:bolder;
  height:60px;
  color: #9e2a22; /* 设置点击后的字体颜色 */
  font-size:28.8px;
}
div >>>.main_tab .el-tabs__active-bar{
    height:8px;
    width:144px;
    background-color:#9e2a22;
}
div >>>.main_tab .inner_tab .el-tabs__item{
 font-size:21.6px;
  font-family:'黑体';
  font-weight:bolder;
  height:60px;
  color: #888585;
}
div >>>.el-tabs__header{
    margin: 0 0 0px;
}
div >>>.main_tab .inner_tab .el-tabs__item.is-active{
    font-size:21.6px;
    font-family:'黑体';
    font-weight:bolder;
    height:60px;
    color: #9f4242;
}
div >>>.main_tab .inner_tab .el-tabs__active-bar{
    height:5px;
    width:100px;
    background-color:#9e2a22;
}
.carousel-item {
    position: relative;
    height: 100%;
}
.overlay_title{
    color: #fff;
    font-family: '楷体';
    font-size:21.6px;
    margin:28px;
}
.overlay {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background-color: rgba(0, 0, 0, 0.5); /* 设置遮罩层的颜色和透明度 */
}
div >>>.el-carousel__indicators button {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 0;
  margin: 0 5px;
  border: none;
}
.more{
    cursor: pointer;
    float:right;
    margin-top:7.2px;
    margin-right:14.4px;
}
.more:hover{
    color:#9e2a22;
    font-weight: bolder;
}

</style>
