<template>
    <div class="box">
        <div class="leftbox">
            <div class="monument">
                <img src="/images/人民英雄纪念碑.png">
            </div>
            <div class="heroname">{{ name }}</div>
            
            <router-link :to="{name:'xianhuaVenue',params:{id:obj.id,heroname:obj.soldierName}}" class="routes"><button class="btn">献花</button></router-link>
        </div>
        <div class="rightbox">
            <div class="title">
                <h1>基本信息</h1>
                <div class="wire">
                    <div class="redwire"></div>
                    <div class="graywire"></div>
                </div>
            </div>
            <div class="row1">
                <h3 class="rowbox">性别：{{obj.sex}}</h3>
                <h3 class="rowbox2">籍贯：{{ obj.nativePlace }}</h3>
            </div>
            <div class="row1">
                <h3 class="rowbox">政治面貌：</h3>
                <h3 class="rowbox2">出生日期：</h3>
            </div>
            <div class="row1">
                <h3 class="rowbox">入伍时间：{{ obj.joinTime }}</h3>
                <h3 class="rowbox2">生前单位：{{ obj.troop }}</h3>
            </div>
            <div class="row1">
                <h3 class="rowbox">生前职务：{{ obj.job }}</h3>
                <h3 class="rowbox2">牺牲日期：</h3>
            </div>
            <div class="title">
                <h1>烈士事迹</h1>
                <div class="wire">
                    <div class="redwire"></div>
                    <div class="graywire"></div>
                </div>
                <h3>{{ this.str }}</h3>
            </div>
        </div>
    </div>
</template>
<script>
import service from '../axios/axios'

export default ({
    name:'soldierXiangqing',
    data(){
        return{
            name:'',
            obj:{},
            total:0,
            str:"",
            id:1,
        }
    },
    methods:{
        //获取烈士的详细信息
        getdata(){
            service.get(`/soldierDetail/${this.id}`)
            .then(res=>{
                console.log(this.id);
                console.log(res);
                this.obj=res.data.data
                this.str=this.obj.soldierName+","+this.obj.sex+","+this.obj.joinTime+","+this.obj.achievement+","+"时年"+this.obj.age+"岁"+"。"
                // console.log(this.obj)
            })
            .catch(err=>{
                console.log(err)
            })
        },
    },
    mounted(){
        this.name=this.$route.params.name;
        this.id=this.$route.params.eid;
        this.getdata()
    }
})
</script>

<style scoped>
.box{
    width:1440px;
    height:720px;
    background-color: rgb(244, 247, 247);
    display:flex;
    flex-direction: row;
}
.leftbox{
    width:432px;
    display:flex;
    flex-direction: column;
}
.rightbox{
    width:1008px;
}
.monument{
    margin-top:72px;
    margin-left:117px;
}
.monument img{
    width:230px;
}
.heroname{
    font-family: KaiTi;
    font-size: 27px;
    margin-left:198px;
    margin-top:18px;
}
.btn{
    cursor: pointer;
    width:144px;
    height:45px;
    margin-left:144px;
    margin-top:36px;
    border: none;
    background-color:rgb(241, 40, 40);
    color:aliceblue;
    font-size:36px;
    font-family: KaiTi;
    border-radius: 9px;
}
.btn:hover{
    background-color:rgb(198, 26, 26);
}
.title h1{
    font-family: KaiTi;
    color:#DC162E;
}
.wire{
    display:flex;
    flex-direction: row;
}
.redwire{
    background-color:#D9001B;
    width:288px;
    height:4.5px;
}
.graywire{
    width:591px;
    height:1.8px;
    background-color:#C7C7C7;
    margin-top:1.8px;
}
.rowbox{
    width:360px;
    height:45px;
}
.rowbox2{
    width:360px;
    height:45px;
    margin-left:216px;
}
.rightbox h3{
    font-family: KaiTi;
    font-size:27px;
    font-weight: 200;

}
.row1{
    height:72px;
    display:flex;
    flex-direction: row;
    margin-top:9px;
}

</style>