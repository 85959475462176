import { render, staticRenderFns } from "./WebFirstPage.vue?vue&type=template&id=2c7a35a5&scoped=true"
import script from "./WebFirstPage.vue?vue&type=script&lang=js"
export * from "./WebFirstPage.vue?vue&type=script&lang=js"
import style0 from "./WebFirstPage.vue?vue&type=style&index=0&id=2c7a35a5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7a35a5",
  null
  
)

export default component.exports