<template>
    <div style="background-color:rgba(235,235,235,0.58);">
        <div v-show="isactive" class="xianhua">
            <img src="/images/flower.png"/>
        </div>
        <div v-show="isshow" class="dianlazhu">
            <img src="/images/lazhu.png"/>
        </div>
        <div class="box">
                <button class="btn1" @click="xianhuabtn" ></button>
                <button class="btn2" @click="dianlazhubtn"></button>
                <h3>已有{{ flowerNumber }}人为{{name}}烈士献花</h3>
        </div>
      <div>
        <img src="/images/留言.png"  style="width:400px;height:100px;margin-left:70px;"/>
      </div>
      <div style="position: absolute;margin-top:-50px;margin-left:220px;font-family: KaiTi;font-size:25px;color:white;">热门留言</div>
<!--        <textarea v-model="textarea" placeholder="请在下方选择您英烈的寄语..." class="inputbox" rows="20" cols="170"></textarea>-->
<!--        <div class="container">-->
<!--            <div class="nameinput">我是：<input v-model="inputname" style="width:500px;"/></div>-->
<!--        <el-dropdown split-button type="danger" @command="handleCommand" class="select">-->
<!--                选择寄语-->
<!--                <el-dropdown-menu slot="dropdown">-->
<!--                    <el-dropdown-item command="item1">向英雄烈士致敬</el-dropdown-item>-->
<!--                    <el-dropdown-item command="item2">革命烈士永垂不朽</el-dropdown-item>-->
<!--                    <el-dropdown-item command="item3">学习英烈精神，汲取奋进力量</el-dropdown-item>-->
<!--                    <el-dropdown-item command="item4">缅怀英烈事迹，奋进伟大征程</el-dropdown-item>-->
<!--                    <el-dropdown-item command="item5">山河已无恙，英烈请安息</el-dropdown-item>-->
<!--                </el-dropdown-menu>-->
<!--        </el-dropdown>-->
<!--        </div>-->
<!--        <button class="submit" @click="submit">发布留言</button>-->
        <div class="showmessage">
          <div style="display:flex;flex-direction: row;">
            <span style="font-size:22px;font-family: KaiTi;">我是来自芜湖市的一名消防员对烈士们说:</span>
            <span style="font-size:22px;font-family: KaiTi;margin-left:10px;">山河已无恙，英烈请安息。</span>
          </div>
          <el-divider></el-divider>
          <div style="display:flex;flex-direction: row;">
            <span style="font-size:22px;font-family: KaiTi;">我是来自芜湖市的一名中学生对烈士们说:</span>
            <span style="font-size:22px;font-family: KaiTi;margin-left:10px;">向英雄烈士致敬！</span>
          </div>
          <el-divider></el-divider>
          <div style="display:flex;flex-direction: row;">
            <span style="font-size:22px;font-family: KaiTi;">我是来自芜湖市的一名教师对烈士们说:</span>
            <span style="font-size:22px;font-family: KaiTi;margin-left:10px;">功勋彪炳史册，精神永垂不朽。</span>
          </div>
          <el-divider></el-divider>
          <div style="display:flex;flex-direction: row;">
            <span style="font-size:22px;font-family: KaiTi;">我是来自芜湖市的一名大学生对烈士们说:</span>
            <span style="font-size:22px;font-family: KaiTi;margin-left:10px;">致敬英烈，弘扬英烈精神，汲取前进力量。</span>
          </div>
          <el-divider></el-divider>
<!--            <div v-for="(item,index) in message" :key="index">-->
<!--                <h2>芜湖市网友</h2>-->
<!--                <div class="like">-->
<!--                    <h3 >{{ item.content }}</h3>-->
<!--                    <div style="display:flex;flex-direction: column;width:216px;margin-left:648px;height:72px;">-->
<!--                        <h4 style="width:43.2px;text-align: center;">{{item.likenum}}</h4>-->
<!--                        <img @click="dlike(item.id,item.likenum)" v-show="isshow1" alt="我在这" src="/images/点赞.png"/>-->
<!--                        <img v-show="isshow2" src="/images/点赞后.png"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="hengxian" style="margin-top:36px;"></div>-->
<!--            </div>-->
<!--            <el-pagination class="fenye"-->
<!--                background-->
<!--                @current-change="handleCurrentChange"-->
<!--                :page-size="pageSize"-->
<!--                layout="total,prev, pager, next, jumper"-->
<!--                :total="total">-->
<!--            </el-pagination>-->
        </div>
    </div>
</template>
<script>
import service from '../axios/axios'
export default ({
   name:'xianhuaVenue',
   data(){
    return {
        commandmessage:{
            'item1': '向英雄烈士致敬',
            'item2': '革命烈士永垂不朽',
            'item3': '学习英烈精神，汲取奋进力量',
            'item4': '缅怀英烈事迹，奋进伟大征程',
            'item5': '山河已无恙，英烈请安息'
        },
        total:1,
        pageSize:4,
        textarea:'',
        flowerNumber: '',
        inputname:'',
        comment:'',
        allmessages:[],
        message:[],
        isactive:false,
        isshow:false,
        id:0,
        name:'',
        dataList:[],
        isDisabled:false,
        isclick:false,
        obj:{},
        num:0,
        likenum:0,
        isshow1:true,
        isshow2:false,
    }
   },
   methods:{
    //增加过的点赞数返回给后端
    dlike(e,f){
        this.isshow1=false
        this.isshow2=true
        f++
        service.put(`/local/comment/like/${e}`,{
            likeNum:f
        })
        .then(res=>{
            console.log(res)
        })
        .catch(error => {
            console.error("An error occurred:", error);
        });
    },
    handleCommand(command){
        this.textarea=this.commandmessage[command]
    },
    //换页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPageData(val); // 重新获取当前页的数据
    },
    //获取当前花数
    getdata(){
        service.get('/foreign/dedicate/mess',{
                params: {
                    id:this.id
                }
            })
        .then(res=>{
            this.flowerNumber=res.data.data.flowerNumber
        })
        .catch(err=>{
            console.log(err)
        })
    },
    //点击献蜡烛
    dianlazhubtn(){
        this.isshow=true
        if(!this.isclick) {
            this.isclick=true
        }
        else{
            alert("您已点过蜡烛！")
        }
    },
    //点击献花
    xianhuabtn(){
        this.isactive=true
        const iid = Number(this.id)
        if (!this.isDisabled){
            // this.flowerNumber++
             //献花数变化后返回后端
            this.isDisabled=true
            service.get('/foreign/dedicate',{
                params: {
                        id:iid
                    }
            }).then(res => {
              this.flowerNumber = res.data.data.flowerNumber
                if(res.code==200){
                    this.$message({
                        message: '献花成功！',
                        type: 'success'
                    });
                }
            })
            .catch(error => {
                this.$message.error("献花失败！"+error);
            });
        }
        else{
            this.$message({
                message: '您已经献过花了',
                type: 'warning'
                });
        }
    },
    //获取留言列表
    getmessage(){
        service.get('/local/comment/list',{
            params: {
                    id:this.id
                }
        })
        .then(res=>{
            this.allmessages=res.data.data.rows//获取当前烈士的所有留言
            this.total=res.data.data.total
        })
        .catch(err=>{
            console.log(err)
        })
    },
    //获取当前页面留言
    getPageData(index){
        this.message=this.allmessages.slice((index-1)*this.pageSize,index*this.pageSize);
    },
    //点击留言
    submit(){
        if(this.textarea=='')
        {
            alert("请选择留言")
        }
        else{
            this.comment=this.textarea
            //将留言写回后端
            service.get('/foreign/comment/${this.id}/${this.comment}')
            this.getmessage()
            this.inputname = ''
            this.textarea = ''
            this.total++
            alert('发布留言成功！')
        }
    }
   },
   mounted(){
    this.id=this.$route.params.fid;
    this.name=this.$route.params.fheroname;
    //获取当前烈士的献花数
    this.getdata(),
    //获取全部留言
    this.getmessage()
    //获取第一页的留言
    this.getPageData(1);
   }
})
</script>

<style scoped>
.box {
  width:1440px;
  height:810px;
  background-image: url('/public/images/xianhua.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display:flex;
  flex-direction: column;
}
.box h3{
  margin-left: 1037px;
  font-family: KaiTi;
  font-size:26px;
}
.btn1{
  cursor: pointer;
  width:288px;
  height:72px;
  background-color:rgba(240, 248, 255, 0);
  border:none;
  margin-left:1037px;
  margin-top:550px;
}
.btn2{
  cursor: pointer;
  width:288px;
  height:72px;
  margin-left:1037px;
  margin-top:27px;
  border:none;
  background-color:rgba(240, 248, 255, 0);
}
.xianhua{
  position:absolute;
}
.xianhua img{
  width:144px;
  height:90px;
  margin-top:660px;
  margin-left:550px;
}
.dianlazhu{
  position:absolute;
}
.dianlazhu img{
  width:100px;
  height:90px;
  margin-top:657px;
  margin-left:745px;
}
h1{
  font-family: KaiTi;
  margin-left:648px;
}
.inputbox{
  width:80%;
  padding: 10px;
  margin-left: 10%;
  border-radius: 8px ;
  border: 1px solid #f10a0a;
}
.nameinput{
  margin-top:18px;
  font-family: KaiTi;
  font-size:27px;
}
.nameinput input{
  width:216px;
  height:45px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #f10a0a;
}
.container {
  margin-left:100px;
}
.select {
  margin-top:18px;
}
.submit{
  cursor: pointer;
  width:10%;
  height:45px;
  color:aliceblue;
  background-color: #e02121;
  border: 1px solid #f10a0a;
  border-radius: 8px;
  font-family: KaiTi;
  font-size:21px;
  margin-left:85%;
}
.submit:hover{
  background-color:#c22d2d;
}
.showmessage{
  width:1224px;
  height:630px;
  margin-left:100.8px;
  margin-top:18px;
}
.like{
  display:flex;
  flex-direction: row;
}
.like img{
  width:36px;
  height:36px;
  margin-top:-18px;
}
.hengxian{
  width:1152px;
  height:0.9px;
  margin-left:43.2px;
  background-color:rgba(146, 146, 146, 0.734);
}
.showmessage h3{
  margin-left:10px;
  font-size: larger;
  font-family: KaiTi;
}
.showmessage h4{
  margin-left:36px;
  font-family: KaiTi;
}
.fenye{
  margin-top:18px;
  margin-left:504px;
}
</style>

