<template>
    <div class="">
        <el-menu
            :default-active="activeIndex"
            class="top"
            mode="horizontal"
            :router='true'
            @select="handleSelect"
            background-color="#9e2a22"
            text-color="#fff"
            active-text-color="#FFD700">
            <el-menu-item index="/WebFirstPage" style="margin-left: 72px;">首页</el-menu-item>
            <el-menu-item index="/Information/news" >资讯</el-menu-item>
            <!-- <el-menu-item index="/relic" >文物</el-menu-item> -->
            <el-menu-item index="/sacrifice" >虚拟祭扫</el-menu-item>
            <el-submenu index="">
              <template slot="title" ><span class="menu_title">烈士场馆</span></template>
              <el-menu-item index="/Local"  >本地馆</el-menu-item>
              <el-menu-item index="/Allopatric"  >异地馆</el-menu-item>
            </el-submenu>
                
            <el-submenu index="/vr">
              <template slot="title" ><span class="menu_title">VR体验</span></template>
              <el-menu-item index=""  ><a href="http://js.aladn.cn/cs2023_files/%E5%AE%8C%E6%88%90%E5%8C%BA/index.html" class="panorama">
                  全景图
                </a></el-menu-item>
                <el-menu-item index="" ><a href="http://test.aladn.cn/vr/" class="panorama">
                  室内全景展馆
                </a></el-menu-item>
              <!-- <el-menu-item >场馆虚拟漫游</el-menu-item> -->
            </el-submenu>
        </el-menu>
        <div class="line"></div>
    </div>
</template>
  <script>
    export default {
      data() {
        return {
          activeIndex:'/WebFirstPage'
        };
      },
      methods: {
        handleSelect(key, keyPath) {
            this.activeIndex=key;
            if(key==='/Information/news')this.$bus.$emit('TopMenuIndex',this.activeIndex);
        },
        changeIndex(val){
          if(val==='/Information/activity')
          this.activeIndex = '/Information/news';
          else
          this.activeIndex = val;
        }
      },
      mounted() {
        const index = this.$route.path;
       // 更新 activeIndex 的值
        this.activeIndex = index;
        this.$bus.$on('activeIndex',this.changeIndex);
    },
    beforeDestroy() {
        this.$bus.$off('activeIndex',this.changeIndex);
    }
    }
  </script>
<style scoped>
    .top{
        font-family: '楷体';
        font-weight: bolder;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    .top:hover{
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.2);
    }
    .top .el-menu-item {
        font-size: 30px;
        margin-right: 72px;
    }
    .top .el-menu-item:hover {

    }
    div >>>.el-submenu{
        font-size: 30px;
        margin-right: 72px;
    }
      a.panorama {
        color: #fff;
        text-decoration: none;
      }
     

    div >>>.el-submenu:hover {

    }
    .menu_title {

        font-size:30px;
    }
    li {

        height: 60px;
      }
    .menu_title:hover {


    }
</style>
