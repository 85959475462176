import Vue from 'vue'
import VueRouter from 'vue-router'
import WebFirstPage from '@/pages/WebFirstPage.vue'
import InformationPage from '@/pages/InformationPage.vue'
import ArticleDetail from '@/pages/ArticleDetail.vue'
import NewsItem from '@/components/NewsItem.vue'
import ActivityItem from '@/components/ActivityItem.vue'
import Local from '@/pages/Local.vue'
import Allopatric from '@/pages/Allopatric.vue'
import Panorama from '@/pages/Panorama.vue'
import xianhuaVenue from '@/components/xianhuaVenue.vue'
import soldierXiangqing from '@/components/soldierXiangqing.vue'
import foreignXianhua from '@/components/foreignXianhua.vue'
import mark from '@/pages/mark.vue'
import relic from '@/components/relic.vue'
import Sacrifice from '@/pages/Sacrifice.vue'
Vue.use(VueRouter)
//路由器
export default new VueRouter({
    routes:[
        {
            path:'/',
            redirect:'/WebFirstPage'
        },
        {
            path:'/WebFirstPage',
            component:WebFirstPage
        },
        {
            path:'/mark',
            component:mark
        },
        {
            path:'/Local',
            component:Local
        },
        {
            path:'/Panorama',
            component:Panorama
        },
        {
            path:'/Allopatric',
            component:Allopatric
        },
        {
            path:'/xianhuaVenue/:id/:heroname',
            name:'xianhuaVenue',
            component:xianhuaVenue
        },
        {
            path:'/soldierXiangqing/:name',
            name:'soldierXiangqing',
            component:soldierXiangqing
        },
        {
            path:'/foreignXianhua/:fid/:fheroname',
            name:'foreignXianhua',
            component:foreignXianhua
        },
        {
            path:'/Information',
            component:InformationPage,
            children: [
                {
                  path: 'news',
                  name: 'News',
                  component: NewsItem
                },
                {
                  path: 'activity',
                  name: 'Activity',
                  component: ActivityItem
                }
              ]
        },
        {
            path:'/ArticleDetail',
            component:ArticleDetail
        },
        {
            path:'/relic',
            name:'relic',
            component:relic
        },
        {
            path:'/sacrifice',
            name:'sacrifice',
            component:Sacrifice
        }
    ]
})
