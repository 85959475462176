<template>
  <div >
    <div class="contain">
        <div>
            <el-page-header @back="goBack" content="正文" >
                        </el-page-header>
            <!-- <el-divider></el-divider> -->
        </div>
        <div class="detail-title">

            <div class="title-info">
               <span style="margin-right:43.2px">标题:{{article.title}}</span>
                <span style="margin-right:43.2px">更新时间:{{article.updateTime}}</span>
                <span style="margin-left:43.2px">浏览量:{{ article.viewCount }}</span>
                <span style="margin-left:43.2px">作者:{{article.author}}</span>
            </div>
        </div>
            <el-divider></el-divider>
            <div >
        <div>
            <mavon-editor
                    class="md"
                    :toolbars="toolbars"
                    :value="article.content"
                    :subfield="false"
                    default-open="preview"
                    :editable="false"
                    :scroll-style="true"
                    @navigationToggle="addUrl"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import service from '../axios/axios';
import markdownData from '../assets/Part1.md';
  export default {
    methods: {
      goBack() {
        this.$router.go(-1);//跳回到原位置
      },
      getData(){
          if(this.articleType==1){
              service.get(`/article/${this.articleId}`)
                  .then(response => {
                      console.log("请求文章");
                      console.log(response.data);
                      this.article.title=response.data.data.title;
                      this.article.content=response.data.data.content;
                      this.article.viewCount=Number(response.data.data.viewCount);
                      this.article.updateTime=response.data.data.updateTime;
                      this.article.author=Number(response.data.data.updateBy);
                  })
                  .catch(error => {
                      this.$message.error('请求失败：'+error);
                  })
          }else{
              service.get(`/relic/${this.articleId}`)
                  .then(response => {
                     console.log()
                      this.article.content=response.data.data.content;
                      this.article.updateTime=response.data.data.updateTime;
                      this.article.author=Number(response.data.data.updateBy);
                  })
                  .catch(error => {
                      this.$message.error('请求失败：'+error);
                  })
          }
      },
    },
    data() {
      return {
          toolbars: {
              navigation: true ,// 导航目录
              readmodel: true, // 读写模式
              htmlcode: true, // HTML 源码
              help: true, // 帮助
              /*
              以上为想要显示的菜单项，
              如果不想显示某个菜单项，
              只需要把其对应的值设置为 false 即可
              */
              undo: false, // 撤销
              redo: false, // 重做
              trash: false, // 清空
              save: false, // 保存（仅在开启 localSave 时出现）
          },
          articleId:0,
          articleType:0,
        article:{
            title:"烈士接口文档",
            content:markdownData,
            viewCount:1,
            updateTime:"2024-05-14 07:59:22",
            author:"匿名"
        }
      }
    },
    mounted(){
            this.articleId=this.$route.query.articleId;
            this.articleType=this.$route.query.type;
            console.log(this.articleId)
            console.log(this.articleType)
            this.getData();
    }
  }
  
</script>
<style scoped>
.markdown-body {
    box-sizing: border-box;
    min-width: 1000px;
    min-height:600px;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 2px 4px 6px gray;
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 40px;
    padding-bottom: 45px;
}
    .contain{
      position: relative;
      top: 0;
      left: 0;
      width: 1440px;
      min-height: 50px;
      /* background-color: rgb(252, 232, 162); */
     /* // background-image: url('../img/阅读.jpeg'); */
      background-size: 100% 100%;
    }
    .detail-title{
        display: flex;
        justify-content: flex-end;
        margin-right:25px;
    }
    .title{
        color:black;
        font-family: '宋体';
        font-size:43.2px;
        font-weight: bolder;
    }
    .title-info{
        color:gray;
        display: flex;
        flex-direction: row;

        margin-top:0px;
    }
    .detail-content{
        font-size: 21.6px;
        font-family: '楷体';
        text-indent: 2em;
        margin:30px;
    }

</style>
