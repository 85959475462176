<template>
  <div class="Link_show">
    <div>  
         <el-divider content-position="center">烈士场馆</el-divider>
    </div >
    <div class="show-bottom">
        <el-button  @click="TurntoLocal()">本地场馆</el-button>
        <el-button  @click="TurntoAllopatric()">异地场馆</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    TurntoLocal(){
      this.$router.push('/Local');
      this.$bus.$emit('activeIndex','/Local');
    },
    TurntoAllopatric(){
      this.$router.push('/Allopatric');
      this.$bus.$emit('activeIndex','/Allopatric');
    }
  }
}
</script>

<style scoped>
div >>>.el-divider__text{
  font-size: 46px; /* 设置字体大小 */
  font-family: "华文行楷";
  font-weight: bold; /* 设置字体粗细 */
  color: rgb(96, 19, 19); /* 设置字体颜色 */
  /* 可以根据需要添加其他字体样式，比如字体类型、字体样式等 */
}
.show-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
div >>>.el-button{
    cursor: pointer;
    text-align: center;
    justify-content: center;
    align-content: center;
    color:white;
    font-size: 36px;
    font-family: '楷体';
    font-weight: bolder;
    background-color: brown;
    width: 432px;
    height: 144px;
    margin-bottom:43.2px;
    margin-top:43.2px;
}
div >>>.el-button:hover{
    background-color: rgb(96, 19, 19);
}
</style>