import { render, staticRenderFns } from "./localVenue.vue?vue&type=template&id=5be16d52&scoped=true"
var script = {}
import style0 from "./localVenue.vue?vue&type=style&index=0&id=5be16d52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be16d52",
  null
  
)

export default component.exports