<template>
    <div>
    <div class="activity-show">
            <div>
                <div class="contain_check">
                    <div class="block">
                        <span class="demonstration" style="font-family: 楷体;font-weight: bolder;">选择起止时间：</span>
                        <el-date-picker
                        unlink-panels
                        v-model="value"
                        type="daterange"
                        format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                        </el-date-picker>
                        <el-button type="" @click="sure()">确定</el-button>
                    </div>
                    <div class="input-container">
                        <input type="text" placeholder="请输入搜索内容" class="search" v-model="title" clearable>
                        <el-button type="" icon="el-icon-search" @click="search()">搜索</el-button>
                    </div>
                  <div>
                    <el-button @click="backsearch" style="margin-top:30px;">搜索返回</el-button>
                  </div>
                </div>
                <div>
                  <ul class="activity-grid">
                      <li v-for="item in items" :key="item.id" @click="showArticle(item.id)">
                          <el-card shadow="hover" >
                              <div class="activity-item">
                                  <div class="item-top">
                                      <div><el-image
                                              style="width: 280px; height: 180px"
                                              :src=item.thumbnail
                                            ></el-image>
                                      </div>
                                  </div>
                                  <div class="item-bottom">
                                    <el-tooltip :content="item.title" placement="top-start" effect="light">
                                      <div class="item-title">{{item.title}}</div>
                                    </el-tooltip>
                                    <el-tooltip :content="item.summary" placement="bottom-start" effect="light">
                                      <div class="item-summary">{{ item.summary }}</div>
                                    </el-tooltip>
                                      <div class="item-date">{{ item.updateTime }}</div>
                                  </div>
                              </div>
                          </el-card>
                      </li>
                  </ul>
                </div>
                <div class="block">
                    <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[8, 16, 24, 36]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                    </el-pagination>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import service from '../axios/axios'
export default {
    methods: {
      handleSizeChange(val) {
        this.pageSize=val;
        //this.changItems(this.currentPage,this.pageSize);
        if(this.issearch === 0) {
          this.getData();
        } else {
          this.items = this.searchitems.slice(0,val)
        }
      },
      handleCurrentChange(val) {
        this.currentPage=val;
       // this.changItems(this.currentPage,this.pageSize);
        if(this.issearch === 0) {
          this.getData();
        } else {
          this.items = this.searchitems.slice((val-1)*this.pageSize,(val-1)*this.pageSize+this.pageSize)
        }
      },
      showArticle(val){
        this.$router.push({path:'/ArticleDetail',query:{articleId:val,type:1}});
    },
    changItems(cur,num){//前端实现分页逻辑
        this.items=[];
        if(cur*num<this.total)
            for(var i=(cur-1)*num;i<cur*num;i++){
                this.items.push(this.Allitems[i]);
            }
        else
            for(var i=(cur-1)*num;i<this.total;i++){
                    this.items.push(this.Allitems[i]);
            }
    },
    sure(){
      console.log(this.value);
      if( this.value ===''){
        this.$message({
                message: '请选择时间！',
                type: 'warning'
                });
               this.getData()
      }
      else{
        service.get('/activity/filter',{
            params: {
                startTime: this.value[0],
                endTime: this.value[1]
            }
        })
          .then(response => {
            this.startTime = ''
            this.endTime = ''
            this.code=response.data.code;
            this.issearch = 1
              this.items=[]
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.searchitems.push(item)
                this.items = this.searchitems.slice(0,this.pageSize)
              }
              this.total=Number(response.data.data.total)
            this.message=response.data.message;
          })
          .catch(error => {
            this.$message.error('错了哦，这是一条错误消息');
            this.startTime = ''
            this.endTime = ''
      })
    //  this.changItems(this.currentPage,this.pageSize);
    }
    },
      // 搜索返回
      backsearch() {
        this.searchitems = []
        this.issearch = 0
        this.currentPage = 1
        this.getData()
      },
    search(){
      if(this.title ===''){
        this.$message({
                message: '请输入内容！',
                type: 'warning'
                });
        this.getData()
      }
      else{
        service.get('/activity/search',{
            params: {
                title: this.title
            }
        })
          .then(response => {
            this.code=response.data.code;
              this.items=[]
            this.issearch = 1
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.searchitems.push(item)
                this.items = this.searchitems.slice(0,this.pageSize)
              }
              this.total=Number(response.data.data.total)
            this.message=response.data.message;
          })
          .catch(error => {
            this.$message.error('没有搜索到相关内容！');
      })
      // this.changItems(this.currentPage,this.pageSize);
    }
    },
    getData(){
      service.get('/activity/list',{
            params: {
                pageNum: this.currentPage,
                pageSize: this.pageSize
            }
        })
          .then(response => {
            this.code=response.data.code;
              this.items=[]
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.items.push(item)
              }
              this.total=Number(response.data.data.total)
            this.message=response.data.message;

          })
          .catch(error => {
            this.$message.error('没有搜索到相关内容！');
      })
    }
    },
    data() {
      return {
        issearch: 0,
        searchitems: [],
        code:'',
        items:[],
        Allitems: [
          { id: 1, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 2, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 3, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 4, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 5, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 6, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 7, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 8, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 9, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 10, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 11, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 12, title: '活动名称',thumbnail:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",summary:"内容摘要",updateTime:"2022-4-15"}
        ],
        currentPage: 1,
        pageSize: 8,
        total:12,
        message:'',
        title:'',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value: ''
      };
},
mounted(){
    // this.changItems(this.currentPage,this.pageSize);
    // this.total=this.Allitems.length;
     this.getData();
}
}
</script>

<style scoped>

.activity-show{
  margin:0;
}
.el-button{
  background: #8b2722;
  color:#fff;
  margin-left: 14.4px;
}
.el-button:hover{
  background: #cb332b;
}
.contain_check{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.input-container {
    display: flex;
    align-items: center; /* 垂直居中对齐 */
    justify-content: right;
    margin-right:28.8px;
}
.input-container input{
  width:288px;
  height:28.8px;
}
.input-container .el-button{
  background: #8b2722;
  color:#fff;
  margin-left: 14.4px;
}
.input-container .el-button:hover{
  background: #cb332b;
  color:#fff;
  margin-left: 14.4px;
}
div >>>.block{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom:30px;
    }
div >>>.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #a82f2f;
    color: #FFF;
}

ul{
    list-style: none;
    margin-top:0px;
}
.activity-grid {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  grid-row-gap: 40px; /* 设置行与行之间的间隔 */
  grid-column-gap: 10px; /* 设置列与列之间的间隔 */
}

div >>>.el-card{
    cursor: pointer;
    width:320px;
    height: 331px;
    box-shadow: 0 2px 6px rgba(105, 20, 20, 0.929);
    transition: box-shadow 0.3s ease; /* 添加过渡效果 */

}
div >>>.el-card:hover{
    background-color: rgb(229, 185, 65);
}

.item-bottom{
    margin-top:14.4px;
    display: flex;
    flex-direction: column;
}
.item-title{
    font-size: 21.6px;
    font-family: '楷体';
    font-weight: bolder;
    width:300px;
    white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 使用省略标记 */
    cursor: pointer; /* 将鼠标样式改为可点击 */
}
.item-title:hover{
    font-size: 21.6px;
    font-family: '楷体';
    font-weight: bolder;
    color:rgb(180, 36, 17);
}
.item-summary{
    font-size: 21.6px;
    font-family: '楷体';
    margin-top:14.4px;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden;   /* 隐藏超出的内容 */
    text-overflow: ellipsis; /* 超出的部分用省略号表示 */
}
.item-date{
    font-size: 14.4px;
    font-family: '楷体';
    margin-top:20px;
}
</style>
