import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/router.js'
//import axios from 'axios'
import './mock'
import store from './store';
Vue.config.productionTip = false;
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
//Vue.prototype.$axios=axios
Vue.use(VueRouter);
Vue.use(mavonEditor)
Vue.use(ElementUI);
router.afterEach( ( to, from, next ) => {
  setTimeout(()=>{
    // var _hmt = _hmt || [];
    // (function() {
    //  //每次执行前，先移除上次插入的代码
    //  document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
    //  var hm = document.createElement("script");
    //  hm.src = "https://hm.baidu.com/hm.js?2c306f0461ffaca336e95707155d8b09";
    //  hm.id = "baidu_tj"
    //  var s = document.getElementsByTagName("script")[0];
    //  s.parentNode.insertBefore(hm, s);
    // })();
    // var _hmt = _hmt || [];
    // (function() {
    //   var hm = document.createElement("script");
    //   hm.src = "https://hm.baidu.com/hm.js?2c306f0461ffaca336e95707155d8b09";
    //   var s = document.getElementsByTagName("script")[0]; 
    //   s.parentNode.insertBefore(hm, s);
    // })();
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?10ffa078090fbb4597e731d1c84e8396";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
  },0);
 } );
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	}
});
