<template>
  <div class="box">
<!--    <el-divider>文物展示</el-divider>-->
    <div class="showframe">
      <img src="/images/左边框.png"  style="width:50px;height:50px;">
      <span style="font-family: KaiTi;line-height:50px;font-size: 30px;color:#BBA364;font-weight: bold;">文物一览</span>
      <img src="/images/右边框.png"  style="width:50px;height:50px;">
    </div>
    <div style="display:flex;flex-direction: row;">
      <el-input
          style="width:250px;margin-left:1000px;"
          placeholder="请输入内容"
          v-model="input"
          clearable>
      </el-input>
      <el-button  style="background-color: #9e2a22;color:white;" @click="search">搜索</el-button>
    </div>
    <div style="margin-left: 50px;margin-top: 50px;">
      <ul class="imgbox">
        <li v-for="(item,index) in reliclist" :key="index" title="点击查看详细信息" @click="showdetail(item.id)">
          <div style="width:50px;height:3px;background-color: #714627;"></div>
            <img style="width:200px;height:160px;margin-top:10px;" :src="item.thumbnail">
          <span style="font-family: KaiTi;font-size:20px;margin-top: 15px;">{{ item.relicName }}</span>
          <span style="font-family: KaiTi;font-size:13px;margin-top: 15px;">更新时间：{{item.updateTime}}</span>
        </li>
      </ul>
    </div>
    <el-pagination
        style="margin-top:50px;margin-left: 30%;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <el-button  style="background-color: #9e2a22;color:white;margin-left: 1200px;" @click="backsearch" v-show="isshow">搜索返回</el-button>
    <div>
      <el-dialog
          title="文物详情"
          :visible.sync="dialogVisible"
          width="30%"
          append-to-body>
        <div style="display:flex;flex-direction: row;margin-top: -25px;font-size: 20px;font-family: KaiTi;color:#BBA364;">
          <img src="/images/文物装饰.png"  style="width:22px;height:22px;">
          <span>陵园文物</span>
          <div style="width:1.5px;height:20px;background-color: #BBA364;margin-left: 10px;"></div>
          <span style="margin-left: 10px;">诉说革命岁月的辉煌与沧桑</span>
        </div>
        <div style="display:flex;flex-direction: column;font-family: KaiTi;margin-top: 20px;">
          <div style="font-size: 18px;">文物名称：{{detailrelic.relicName}}</div>
          <div style="font-size: 18px;margin-top:10px;">文物来源：{{detailrelic.source}}</div>
          <div style="font-size: 18px;margin-top:10px;">文物年代：{{detailrelic.era}}</div>
          <div style="font-size: 18px;margin-top:10px;">文物介绍内容：{{detailrelic.content}}</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import service from '../axios/axios'
export default  ({
  data() {
    return {
      reliclist:[
        // {id:1, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:2, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:3, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:4, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:5, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:6, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:7, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
        // {id:8, relicName: '纪念碑', thumbnail: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', updateTime: '2024-4-16 07:59:22'},
      ],
      total: 20,
      pageSize: 8,
      currentPage: 1,

      dialogVisible: false,
      detailrelic: {
        id: 1,
        relicName: '纪念碑1',
        source: '未知',
        era: '未知',
        content: '革命烈士纪念碑',
        updateTime:'2024-4-16 07:59:22',
        updateBy:1
      },
      i: 0,

      // 搜索
      input: '',
      searchlist: [],
      issearch: 0,
      isshow: false
    }
  },
  mounted() {
    this.currentPage = 1
    this.getreliclist()
  },
  methods:{
    // 获取文物列表
    getreliclist() {
      service.get('/relic/list', {
        params: {
          pageNum: this.currentPage,
          pageSize: 8
        }
      }).then(res => {
        this.reliclist = res.data.data.rows.map(item => ({
          ...item,
          id: Number(item.id)
        }))
        this.total = Number(res.data.data.total)
      }).catch(erro => {
        console.log('获取文物列表失败', erro)
      })
    },
    // 获取文物详细内容
    getdetail() {
      service.get(`/relic/${this.i}`,{
            params:{
              id:this.i
            }
      }
      ).then(res => {
        this.detailrelic = res.data.data
      }).catch(erro => {
            console.log('文物详细信息获取失败', erro)
          })
    },
    // 展示文物详情
    showdetail(item) {
      this.dialogVisible = true
      this.i = item
      this.getdetail()
    },
    // 换页
    handleCurrentChange(val) {
      this.currentPage = val
      if (this.issearch === 1) {
        this.reliclist = this.searchlist.slice((val-1)*8,(val-1)*8+8)
      } else {
        this.getreliclist()
      }
    },
    // 搜索
    search() {
      service.get('/relic/search', {
        params: {
          relicName: this.input,
        }
      }).then(res => {
        this.searchlist = res.data.data.rows
        if( this.searchlist.length === 0) {
          this.$message({
            type: 'info',
            message: '没有找到相关内容'
          })
          this.currentPage = 1
          this.getreliclist()
        } else {
          this.total = Number(res.data.data.total)
          this.reliclist = []
          this.issearch = 1
          this.isshow = true
          this.reliclist = this.searchlist.slice(0,8)
        }
      }).catch(erro =>  {
        console.log('获取搜索列表失败', erro)
      })
      this.input = ''
    },
    // 搜索返回
    backsearch() {
      this.issearch = 0
      this.isshow = false
      this.currentPage = 1
      this.searchlist = []
      this.reliclist = []
      this.getreliclist()
    }
  }
})
</script>

<style scoped>
.box{
  font-family: KaiTi;
  width:1440px;
  height:800px;
  background-color: white;
}
.showframe{
  width:300px;
  height:50px;
  display:flex;
  flex-direction: row;
  margin-left: 43%;
  margin-top: 20px;
}
.imgbox{
  list-style-type: none; /* 移除默认的列表项标记 */
  padding: 0; /* 移除默认的填充 */
  display: grid;
  grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 2fr)
    ); /* 自动填充列，每列最小宽度240px */
  gap: 40px; /* 列之间的间距 */
}
.imgbox li {
  background-color: white; /* 设置背景色为白色 */
  border: 1px solid #ccc; /* 添加边框，颜色为灰色 */
  margin-bottom: 5px; /* 添加下边距，以便每个盒子之间有间隔 */
  /* 可选：添加圆角效果 */
  border-radius: 5px;
  /* 可选：鼠标悬停效果 */
  transition: background-color 0.3s; /* 平滑过渡效果 */
  width:270px;
  height:250px;
  align-items: center;
  justify-items: center;
  display:flex;
  flex-direction: column;
}
.imgbox li:hover{
  background-color: #f0f0f0; /* 浅灰色背景，用于悬停效果 */
}
.box-card{
  width: 350px;
}
</style>
