<template>
    <div class="body">
        <!-- <div v-html="htmlContent"></div> -->
        <mavon-editor  v-model="value" :subfield="prop.subfield" :defaultOpen="prop.defaultOpen" :toolbarsFlag="prop.toolbarsFlag" :editable="prop.editable" :scrollStyle="prop.scrollStyle" :boxShadow="prop.boxShadow" ref=md />
    </div>
</template>
<script>
import MyMarkdown from '../assets/Part1.md';

export default {
    data() {
        return {
         
            value: MyMarkdown,
        };
    },
    computed: {
        prop() {
            return {
                subfield: false,
                defaultOpen: 'preview',
                editable: false,
                toolbarsFlag: false,
                scrollStyle: true,
                boxShadow: true
            };
        }
    },
    // methods: {
    //     getHTMLContent() {
    //         this.$nextTick(() => {
    //             if (this.$refs.md) {
    //                 const htmlContent = this.$refs.md.$refs.edit.mdToHtml(this.value);
    //                 this.htmlContent = htmlContent;
    //                 console.log(htmlContent);
    //             }
    //         });
    //     }
    // },
    // mounted() {
    //     this.getHTMLContent();
    // }
};
</script>
    <style scoped>
   
    </style>