<template>
    <div class="contain">
            <div class="contain_check">
                <div class="block">
                    <span class="demonstration" style="font-family: 楷体;font-weight: bolder;">选择起止时间：</span>
                    <el-date-picker
                    unlink-panels
                    v-model="value"
                    type="daterange"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                    <el-button type="" @click="sure()">确定</el-button>
                </div>
                <div class="input-container">
                            <input type="text" placeholder="请输入搜索内容" class="search" v-model="title" clearable>
                            <el-button type="" icon="el-icon-search" @click="search()">搜索</el-button>
                </div>
              <div>
                <el-button @click="backsearch" style="margin-top:30px;">搜索返回</el-button>
              </div>
            </div>
            <ul>
                <li v-for="item in items" :key="item.id" @click="showArticle(item.id)">
                    <div class="contain_top">
                      <div shadow="hover" class="custom-card">
                          <div>
                            <img :src=item.thumbnail class="custom-card-img">
                          </div>
                          <div class="custom-card-header">
                                  <div class="custom-card-detail">
                                    <el-tooltip :content="item.title" placement="top-start" effect="light">
                                      <div calss="detail-title" style="font-family:华文行楷;font-size: 28.8px;color:black; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
                                          {{ item.title }}
                                      </div>
                                    </el-tooltip>
                                    <el-tooltip :content="item.summary" placement="bottom-start" effect="light">
                                      <div class="detail-summary" style="color:gray;font-size: 14.4px;">
                                          {{ item.summary }}
                                      </div>
                                    </el-tooltip>
                                  </div>
                                  <div>
                                      <span style="color:gray;font-size: 18px;">{{ item.updateTime}}</span>
                                  </div>
                          </div>
                      </div>
                    </div>
                </li>
            </ul>
            <div class="block">
                <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
    </div>
  </template>
  <script>
  import service from '../axios/axios'
  export default {
    methods: {
      handleSizeChange(val) {
        this.pageSize=val;
        // this.changItems(this.currentPage,this.pageSize);
        if(this.issearch === 0) {
          this.getData();
        } else {
          this.items = this.searchitems.slice(0,val)
        }
      },
      handleCurrentChange(val) {
        this.currentPage=val;
        if(this.issearch === 0) {
          this.getData();
        } else {
          this.items = this.searchitems.slice((val-1)*this.pageSize,(val-1)*this.pageSize+this.pageSize)
        }
        // this.changItems(this.currentPage,this.pageSize);
      },
      showArticle(val){
          this.$router.push({path:'/ArticleDetail',query:{articleId:val,type:1}});
    },
    changItems(cur,num){
        this.items=[];
        if(cur*num<this.total)
            for(var i=(cur-1)*num;i<cur*num;i++){
                this.items.push(this.Allitems[i]);
            }
        else
            for(var i=(cur-1)*num;i<this.total;i++){
                    this.items.push(this.Allitems[i]);
            }
    },
    sure(){
      if(this.value===''){
        this.$message({
                message: '请选择时间！',
                type: 'warning'
                });
        this.getData();
      }
      else{
        console.log(this.value[0])
        service.get('/news/filter',{
            params: {
                startTime: this.value[0],//传
                endTime: this.value[1]
            }
        })
          .then(response => {
              this.items=[]
            this.issearch = 1
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.searchitems.push(item)
                this.items = this.searchitems.slice(0,this.pageSize)
              }
              this.total=Number(response.data.data.total)
          })
          .catch(error => {
            this.$message.error('错了哦，这是一条错误消息');
      })
        this.startTime = ''
        this.endTime = ''
    }
    },
      // 搜索返回
      backsearch() {
        this.searchitems = []
        this.issearch = 0
        this.currentPage = 1
        this.getData()
      },
    search(){
      if(this.title === ''){
        this.$message({
                message: '请输入内容！',
                type: 'warning'
                });
      }
      else{
        service.get('/news/search',{
            params: {
                title: this.title
            }
        })
          .then(response => {
            this.issearch = 1
              this.items=[]
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.searchitems.push(item)
                this.items = this.searchitems.slice(0,this.pageSize)
              }
              this.total=Number(response.data.data.total)
          })
          .catch(error => {
          this.$message.error('没有搜索到相关内容！');
      })
    }
    },
    getData(){
      service.get('/news/list',{
            params: {
                pageNum: this.currentPage,
                pageSize: this.pageSize
            }
        })
          .then(response => {
              this.items=[]
              for(let i=0;i<response.data.data.total;i++){
                  let item={
                      id:Number(response.data.data.rows[i].id),
                      summary:response.data.data.rows[i].summary,
                      thumbnail:response.data.data.rows[i].thumbnail,
                      title:response.data.data.rows[i].title,
                      updateTime:response.data.data.rows[i].updateTime
                  }
                  this.items.push(item)
              }
              this.total=Number(response.data.data.total)
          })
          .catch(error => {
            this.$message.error('错了哦，这是一条错误消息');
      })
    }
    },

    data() {
      return {
        code:'',
        issearch: 0,
        items:[],
        searchitems: [],
        nonsearchitems: [],
        Allitems: [
          { id: 0, top:1,title: '标题' ,summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-14"},
          { id: 1, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-17" },
          { id: 2, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-02-19" },
          { id: 3, top:1,title: '标题' ,summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-14"},
          { id: 4, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-17" },
          { id: 5, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-02-19" },
          { id: 6, top:1,title: '标题' ,summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-14"},
          { id: 7, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-17" },
          { id: 8, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-02-19" },
          { id: 9, top:1,title: '标题' ,summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-14"},
          { id: 10, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-05-17" },
          { id: 11, top:1,title: '标题',summary:"内容",thumbnail:"https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",updateTime:"2023-02-19" }
        ],
        title:'',
        currentPage: 1,
        pageSize: 10,
        total:30,
        message:'',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value: ''
      };
  },
  mounted(){
    // this.changItems(this.currentPage,this.pageSize);
    // console.log(this.currentPage+this.pageSize);
    // this.total=this.Allitems.length;
   this.getData();
  }
}
</script>
<style scoped>
.contain_check{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.input-container {
    display: flex;
    align-items: center; /* 垂直居中对齐 */
    justify-content: right;
    margin-right:40px;
}
.input-container input{
  width:288px;
  height:28.8px;
}
.el-button{
  background: #8b2722;
  color:#fff;
  margin-left: 14.4px;
}
.input-container .el-button{
  background: #8b2722;
  color:#fff;
  margin-left: 14.4px;
}
.input-container .el-button:hover{
  background: #cb332b;
  color:#fff;
  margin-left: 14.4px;
}
div >>>.block{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom:30px;
  }
div >>>.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #a82f2f;
    color: #FFF;
}
    ul li {
        cursor: pointer;
        font-size: 28.8px; /* 设置项目符号的大小 */
        color: rgb(159, 58, 58); /* 设置项目符号的颜色 */
        list-style: none;
    }
    .contain_top{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
    }
    .custom-card-img{
        cursor: pointer;
        height: 90px;
        width:216px;
        position: relative;
    }
    div >>>.custom-card {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        margin-left: 5%;
        margin-top:1.5%;
        height: 90px;
        width:90%;
        box-shadow: 0 2px 6px rgba(105, 20, 20, 0.929);
        transition: box-shadow 0.3s ease;
    }
    div >>>.custom-card-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    div >>>.custom-card:hover{
        background-color: rgb(240, 172, 104);
    }
    .custom-card-detail{
        margin-left:20px;
        width:700px;
        display: flex;
        flex-direction: column;
    }
    div >>>.more{
        cursor: pointer;
        font-size: 17px;
    }
    div >>>.more:hover{
        font-size: 21.6px;
        color:brown;
    }
    .detail-title{
      width:580px;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;   /* 隐藏超出的内容 */
      text-overflow: ellipsis; /* 超出的部分用省略号表示 */
    }
    .detail-summary{
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;   /* 隐藏超出的内容 */
      text-overflow: ellipsis; /* 超出的部分用省略号表示 */
    }
</style>
