<template>
    <div>
        <div class="box">
          <el-badge :value="informvalue" class="item1">
            <el-button icon="el-icon-message-solid" style="color: #fa8504;" class="btn" @click="showModal = true"></el-button>
          </el-badge>
        </div>
<!--      通知列表弹窗-->
        <div class="modal" v-if="showModal">
<!--          时间筛选-->
          <div style="margin-top:10px;">
            <span style="margin-left:5%;">选择起止时间：</span>
            <el-date-picker
                style="margin-left: 2%"
                v-model="value2"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
            <el-button type="primary" style="background-color: #b81105;border-color: #b81105;width:80px;margin-left: 3%;" @click="datesure">确定</el-button>
            <button class="el-icon-close"
                    @click="closeModal"
                    style="position: absolute;margin-left:20%;margin-top:-1%;width:35px;height:35px;border: none;background-color: transparent;cursor: pointer;font-size: 25px"></button>
          </div>
            <el-input v-model="input" placeholder="请输入内容" style="width:50%;margin-left:5%;margin-top:2%;"></el-input>
            <el-button type="primary" style="background-color: #b81105;border-color: #b81105;width:100px;" @click="search">搜索</el-button>
            <el-button type="primary" style="background-color: #b81105;border-color: #b81105;width:100px;margin-left:5%;" @click="backsearch">搜索返回</el-button>

            <el-divider></el-divider>
            <div class="moal-content" @scroll="handleScroll">
                <div v-for="(item, index) in items" :key="index" class="item" @click="opendetail(item)">
                     <el-badge class="inform" >
                       <div style="width:500px;">
                         <h3>{{item.title}}</h3>
                         <h4>{{item.summary}}</h4>
                       </div>
                       <div style="width:250px;height:80px;">
                         <h4 style="margin-top:60px;">{{item.updateTime}}</h4>
                       </div>
                     </el-badge>
                </div>
            </div>

        </div>
      <!--          详细信息弹窗-->
      <div class="detail" v-if="isshow">
        <button class="el-icon-close"
                @click="closedetail"
                style="position: absolute;margin-left:10px;width:35px;height:35px;margin-left:500px;
                border: none;background-color: transparent;cursor: pointer;font-size: 25px"></button>
<!--        <div style="margin-top:20px;margin-left:230px;font-family: KaiTi;font-size:20px;">{{ detailitems.title }}</div>-->
        <div>
          <h3 style="text-align: center;font-family: KaiTi;">{{ detailitems.title }}</h3>
          <div style="display: flex;flex-direction: row;font-family: KaiTi;font-size: medium;margin-top:-20px;">
            <h4 style="margin-left: 120px;">浏览量：{{detailitems.viewCount}}</h4>
           <h4 style="margin-left: 100px;">更新时间：{{ detailitems.updateTime }}</h4>
          </div>
          <div style="margin-left: 20px;">{{ detailitems.content }}</div>
        </div>
      </div>
    </div>
</template>

<script>

import service from '../axios/axios'

export default {
    name:'notuFication',
    data(){
        return {
          informvalue: 0,
          input:'', // 输入框
          informlength: 0,
          isshow: false,
          isread: [],
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          showModal: false,
          items: [{
            "id": 1,
            "title": "春江花月夜",
            "summary": "春江潮水连海平，海上明月共潮生...",
            "thumbnail": "https://www.ahnu.edu.cn/ahsf2023/images/logo.png",
            "updateTime":"2024-4-16 07:59:22",
          },
            {
              "id": 2,
              "title": "水调歌头·明月几时有",
              "summary": "明月几时有？把酒问青天...",
              "thumbnail": "https://www.ahnu.edu.cn/ahsf2023/images/logo.png",
              "updateTime":"2024-4-16 07:59:22",
            }],
          detailitems: { title:'标题', content: '内容', viewCount: 0, updateTime: '2024-4-16 07:59:22', updateBy: 1},
          loading: false,
          pageSize: 10,
          value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
          value2: '',
          startTime: '',
          endTime: ''
        }
    },
  mounted() {
      this.getitems()
  },
  methods:{
    openModal() {
          this.showModal = true;
          this.items = []; // 初始化数据
          this.loadMore(); // 加载初始数据
    },
    // 关闭通知列表
    closeModal() {
          this.showModal = false;
        },
    handleScroll(event) {
      const target = event.target;
      const { scrollHeight, scrollTop, clientHeight } = target;

      // 滚动到底部
      if (scrollHeight - scrollTop === clientHeight) {
        this.loadMore();
      }
    },
    loadMore() {
          if (this.loading) return;
          this.loading = true;
    // 模拟异步加载数据
    setTimeout(() => {
            for (let i = 0; i < this.pageSize; i++) {
              this.items.push(`Item ${this.items.length + 1}`);
            }
            this.loading = false;
          }, 1000);
        },
    // 关闭通知详细页面
    closedetail() {
      this.isshow = false
    },
    getitems() {
      service.get('/notification/list')
          .then(res => {
            // console.log('通知获取成功', res.data.message)
            this.items = res.data.data.rows
            this.informvalue = res.data.total
            // console.log('this.items', this.items)
          })
          .catch(erro => {
            console.log('通知获取失败', erro)
          })
    },
    // 获取通知详细内容
    getdetail(item) {
      var id
      id = item.id
      service.get(`/article/${id}`)
          .then(res => {
            this.detailitems = res.data.data
            this.informlength = res.data.data.total
            this.informvalue = res.data.data.total
          })
          .catch(erro => {
            console.log(erro)
          })

    },
    // 搜索
    search() {
      service.get('/notification/search',{
        params: {
          title: this.input
        }
      }).then(res => {
        if (res.data.data.total === 0) {
          this.$message({
            message: '没有找到相关内容',
            type: 'warning'
          });
        } else {
          this.items = res.data.data.rows
          this.isread.length = res.data.data.total
          this.isread.fill(0)
        }
      }).catch(erro => {
        // console.log(111222)
        console.log(erro)
      })
    },
    // 搜索返回
    backsearch() {
      this.getitems()
    },
    // 打开详细通知查看
    opendetail(item) {
      this.isread[item.id-1] = 0
      let isreadString = JSON.stringify(this.isread)
      localStorage.setItem('myArray', isreadString)
      this.isshow = true
      this.getdetail(item)
    },
    // 时间筛选通知
    datesure() {
      if (this.value2 === '') {
        this.$message({
          message: '请选择起始与截止时间',
          type: 'warning'
        });
      } else {
        const startDate = new Date(this.value2[0]);
        const endDate = new Date(this.value2[1])
        this.startTime= this.formatDate(startDate)
        this.endTime= this.formatDate(endDate)
        service.get('/notification/filter',{
          params: {
            startTime: this.startTime,
            endTime: this.endTime
          }
        }).then(res => {
          this.items = res.data.data.rows
          this.value2 = ''
        }).catch(erro => {
          console.log(erro)
        })
      }
    },
    formatDate(date) {
      // 格式化日期为 'YYYY-MM-DD' 形式
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
}
</script>

<style scoped>

.box{
    position: fixed;
    width:70px;
    height:80px;
    z-index:999;
    margin-left: 600px;
    margin-top:-450px;
}
.btn{
    width:70px;
    height: 70px;
    font-size:30px;
    border-radius: 50% 50%;
  border-color: rgb(219, 125, 16);
  background-color: rgba(255, 255, 255, 0.31);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 500px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: KaiTi;
}

.item {
  margin-top: 10px;
  width:80%;
  height:80px;
  margin-left: 20px;
}
.inform{
  margin-left: 50px;
  display:flex;
  flex-direction: row;
  border:0.5px solid #888585;
  width:750px;
  height:79px;
  box-shadow: 2px 2px 5px 0 rgba(136, 133, 133, 0.76)
}
.inform:hover {
  background-color: #eeab67;
}

.detail{
  border: 1.5px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1001;
  width:550px;
  height:300px;
  overflow-y: auto;
  background-color: #f8f5f5;
  margin-left: -280px;
  margin-top:-260px;
  display:flex;
  flex-direction: column;
}
.item1 {
  margin-top: 10px;
  margin-right: 40px;

}
</style>
