<template>
    <div class="outside">
        <div>
            <localVenue></localVenue>
            <venueTable></venueTable>
        </div>
    </div>
</template>
<script>
import localVenue from '../components/localVenue.vue'
import venueTable from '../components/venueTable.vue'
export default {
    components:{
        localVenue,
        venueTable
    }
}
</script>

<style scoped>
.outside{
    width:1440px;
}

</style>