<template>
    <div class="container">
        <div class="btn1" v-show="isshow1" @click="btnclick">点击显示部分烈士名单</div>
        <div class="btn1" v-show="isshow2" @click="btnclick">点击显示全部烈士名单</div>
        <!-- 全部名单 -->
        <div class="tablebox" v-show="showtable1">
        <h3 style="font-family: KaiTi;margin-left:43.2px;margin-top:4.5px;">全部名单</h3>
           <div style="display:flex;flex-direction: row;width:100%;margin-top:-27px;">
            <h3 style="color:brown;margin-left: 4%;font-family: KaiTi;width:30%;">烈士排名不分先后排名</h3>
            <div class="search" v-show="lookfor">
                <el-input class="searchinput"
                    placeholder="请输入烈士姓名"
                    v-model="input"
                    clearable>
                </el-input>
                <el-button  class="searchBtn"  round @click="searchname()">搜索</el-button>
            </div>
           </div>
            <table>
                <tr v-for="(row, rowIndex) in grid" :key="rowIndex">
                    <td v-for="(person, colIndex) in row" :key="`${rowIndex}-${colIndex}`" @click="nameClick1(person)">
                       <router-link :to="{name:'xianhuaVenue',params:{id:person.id,heroname:person.soldierName}}" class="routes" >{{ person.soldierName }}</router-link>
                    </td>
                </tr>
            </table>
            <div class="page">
                <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageNum"
                layout="total,prev, pager, next, jumper"
                :total="total">
                </el-pagination>
              <el-button @click="backsearch" style="margin-left:80px;background-color: #e80d0d;color:white;">搜索返回</el-button>
            </div>
        </div>
        <!-- 部分名单 -->
        <div class="tablebox" v-show="showtable2">
            <h3 style="font-family: KaiTi;margin-left:43.2px;margin-top:4.5px;">部分名单</h3>
           <div style="display:flex;flex-direction: row;width:100%;margin-top:-27px;">
            <h3 style="color:brown;margin-left: 4%;font-family: KaiTi;width:30%;">烈士排名不分先后排名</h3>
            <div class="search" v-show="lookfor">
                <el-input class="searchinput"
                    placeholder="请输入烈士姓名"
                    v-model="input2"
                    clearable>
                </el-input>
                <el-button class="searchBtn" round @click="searchname2()">搜索</el-button>
            </div>
           </div>

            <table>
            <tr v-for="(row, rowIndex) in grid2" :key="rowIndex">
                <td v-for="(person, colIndex) in row" :key="`${rowIndex}-${colIndex}`" @click="nameClick2(person)">
                   <router-link :to="{name:'soldierXiangqing',params:{eid:person.id, name:person.soldierName}}" class="routes">{{ person.soldierName }}</router-link>
                </td>
            </tr>
        </table>
        <div class="page">
        <el-pagination
        background
        @current-change="handleCurrentChangetable2"
        :page-size="pageSize2"
        :current-page="pageNum2"
        layout="total,prev, pager, next, jumper"
        :total="total2">
        </el-pagination>
        </div>

    </div>
    </div>
</template>

<script>
import service from '../axios/axios'
 export default {
    name:'venueTable',
    data(){
        return {
          lookfor:true,
            pageNum:1,//分页数
            pageSize:35,//分页大小（每页包含几个数据）
            currentList:[],//当前页面的数据
            searchlist: [], // 搜索到的列表
            grid: [],//名字的二维数组
            total:1,
            input:'',
            pageNum2:1,//分页数
            pageSize2:35,//分页大小（每页包含几个数据）
            currentList2:[],//当前页面的数据
            grid2: [],//名字的二维数组
            total2:1,
            dataList2:[],
            input2:'',
            isshow1:true,
            isshow2:false,
            showtable1:true,
            showtable2:false,
          issearch: 0,
          filteredList: [],
        }
    },
    methods:{
        nameClick1(person){
            this.$router.push({name:'xianhuaVenue',params:{id:person.id,heroname:person.soldierName}});
        },
        nameClick2(person){
            this.$router.push({name:'soldierXiangqing',params:{eid:person.id, name:person.soldierName}});
        },
        //点击切换全部名单和部分名单
        btnclick(){
            if(this.isshow1==true&&this.showtable1==true)
            {
                this.isshow2=true
                this.showtable1=false
                this.isshow1=false
                this.showtable2=true
              this.lookfor = false
            }
            else {
                this.isshow1=true
                this.showtable2=false
                this.isshow2=false
                this.showtable1=true
              this.lookfor = true
            }
        },
        backsearch() {
          this.issearch = 0
          this.pageNum = 1
          this.gettable()
        },
        //换页
        handleCurrentChange(val) {
          if (this.issearch === 0) {
            this.currentPage = val;
            service.get('/local/list', {
              params: {
                pageNum: val,
                pageSize: 35
              }
            })
                .then(res => {
                  //获取到当前页的数据列表
                  this.currentList = res.data.data.rows;
                  this.total = Number(res.data.data.total)
                  this.getPageData()
                })
                .catch(err => {
                  console.log(err)
                })
          } else {
            this.grid = []
            let arry = []
            arry = this.searchlist.slice((val - 1) * 35, (val - 1) * 35 + 35)
            for (let i = 0; i < 5; i++) {
              this.grid[i] = arry.slice(i * 7, (i + 1) * 7);
            }
            if (val === this.total / 35 + 1) this.issearch = 0
          }
        },

    //换页
    handleCurrentChangetable2(val){
        this.currentPage2 = val;
        this.getcurrentList2(val)//获取当前页表格
        this.getPageData2()//获取当前页展示表格
    },
    //获取全部烈士名单
        gettable(){
            service.get('/local/list', {
                params: {
                    pageNum: 1,
                    pageSize:35
                }
            })
            .then(res=>{
                //获取到当前页的数据列表
                this.currentList=res.data.data.rows;
                this.total=Number(res.data.data.total)
                console.log(this.currentList)
                this.getPageData()

            })
            .catch(err=>{
                console.log(err)
            })
        },
         //获取部分名单烈士
        gettable2(){
            service.get('/soldierDetail/list')
            .then(res=>{
                //获取到当前页的数据列表
                // console.log('英名录名单',res.data.data.rows)
                this.currentList2= res.data.data.rows.filter(item => item.type === '1')
              // const this.filteredList = this.currentList2.filter(item => item.type === 1);
                this.total2=Number(this.currentList2.length);
              //   console.log('本地馆英名录个数',this.currentList2)
              // console.log(this.total2)
                this.getPageData2()

            })
            .catch(err=>{
                console.log(err)
            })
        },
        //全部名单表格：更新表格页面显示数据
        getPageData(){
            this.grid=[];
            for(let i=0;i<5;i++)
            {
                this.grid[i]=this.currentList.slice(i*7,(i+1)*7);
            }
          console.log(this.grid);
        },
        //部分名单表格：更新表格页面显示数据
        getPageData2(){
            this.grid2=[];
            for(let i=0;i<5;i++)
            {
                this.grid2[i]=this.currentList2.slice(i*7,(i+1)*7);
            }
        //   console.log(this.grid2);
        },
        getcurrentList2(val){
            this.currentList2=this.dataList2.slice((val-1)*this.pageSize2,val*this.pageSize2)
        },
        searchname(){
          if(!(/^[\u4e00-\u9fa5]+$/.test(this.input)))
          {
            this.$message({
              message: '请输入合法的名字',
              type: 'warning'
            });
          }else{
                //在后端搜索
                service.get('/local/search', {
                  params: {
                        id: null,
                        soldierName:this.input
                  }
                })
                .then(res=>{
                  let  obj = []
                  obj = res.data.data.rows
                  if (obj.length === 0) {
                    this.$message({
                      message: '没有找到相关数据！',
                      type: 'warning'
                    });
                  } else {
                    this.total = Number(res.data.data.total)
                    this.issearch = 1
                    this.searchlist = []
                    this.searchlist = obj
                    let arry = []
                    arry = this.searchlist.slice(0,35)
                    for (let i = 0; i < 5; i++) {
                      this.grid[i] = arry.slice(i * 7, (i + 1) * 7);
                    }
                  }
                })
                .catch(err=>{
                   this.$message.error('没有搜索到相关数据');
                })
          }
        },
        searchname2(){
            let k=0;
            if(this.input=='')
            {
                this.gettable()
                this.getcurrentList2(1)
                this. getPageData2()
            }
            else{
                for(let i=0;i<this.total;i++){
                if(this.dataList2[i].soldierName==this.input2)
                {
                    k=1;
                }
            }
           if(k==1)
           {
                let obj={
                    'id':1,
                    "soldierName":this.input2
                    }
                    this.total2=1
                    this.grid2=[]
                    this.grid2.push([])//添加一行
                    this.grid2[this.grid2.length-1].push(obj)
                    // console.log(this.grid2)
           }
            else{
                this.$message.error('没有搜索到相关数据');
             }
            }

        }
    },
    mounted() {

      //全部名单：首先展示第一页的数据
        this.gettable()
        //部分名单：首先展示第一页
        this.gettable2();

    },
}
</script>

<style scoped>
.searchBtn{
    background-color: rgb(228, 28, 28);
    color:aliceblue;
    font-family: KaiTi;
    font-size:18px;
}
.searchBtn:hover{
    background-color:brown;
}
.btn1{
    width:172.8px;
    height:36px;
    border-radius: 9px;
    text-align: center;
    line-height:36px;
    color:aliceblue;
    font-family: KaiTi;
    background-color:rgb(198, 26, 26);
    cursor:pointer;
    margin-left:12%;
}

.search{
    width:80%;
    height:10%;
    margin-top:2%;
    display:flex;
    flex-direction: row;
}
.searchinput{
    margin-left:60%;
    width:30%;

}
.container{
    height:880px;
}

.tablebox{
    margin-top:1.5%;
    width:80%;
    height:80%;
    margin-left:12%;
    border:1px solid rgb(228, 28, 28);
}
table{
    border-spacing: 3rem;
    border-collapse: separate;
}
table td{
    cursor: pointer;
    background-image: url('/public/images/背景框.png');
    border:1px solid black;
    background-repeat: no-repeat;
    background-size: contain;
    border:none;
    width:108px;
    height:45px;
    text-align: center;
    line-height: 45px;
    background-position: center;
}

.page {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 如果需要垂直居中也有效 */
}
.routes{
    text-decoration: none;
    color:black;
}
.routes:hover{
    text-decoration: none;
    color:rgb(147, 19, 19);
    font-weight: bolder;
}
</style>
{"id": 1,"soldierName": "俞力行","flowerNumber":99},
        {"id": 2,"soldierName": "章成发","flowerNumber":99},
        {"id": 3,"soldierName": "易永泉","flowerNumber":99},
        {"id":4,"soldierName": "严鑫全","flowerNumber":99},
        {"id": 5,"soldierName": "束维流","flowerNumber":99},
        {"id": 6,"soldierName": "章忠富","flowerNumber":99},
        {"id": 7,"soldierName": "丁祖友","flowerNumber":99},
        {"id": 8,"soldierName": "何忠如","flowerNumber":99},
        {"id": 9,"soldierName": "戴履彬","flowerNumber":99},
        {"id": 10,"soldierName": "商良银","flowerNumber":99},
        {"id": 11,"soldierName": "章成发","flowerNumber":99},
        {"id": 12,"soldierName": "易永泉","flowerNumber":99},
        {"id":13,"soldierName": "严鑫全","flowerNumber":99},
        {"id": 14,"soldierName": "束维流","flowerNumber":99},
        {"id": 15,"soldierName": "易永泉","flowerNumber":99},
        {"id": 16,"soldierName": "商良银","flowerNumber":99},
        {"id": 17,"soldierName": "章成发","flowerNumber":99},
        {"id": 18,"soldierName": "易永泉","flowerNumber":99},
        {"id": 19,"soldierName": "商良银","flowerNumber":99},
        {"id": 20,"soldierName": "章成发","flowerNumber":99},
        {"id": 21,"soldierName": "易永泉","flowerNumber":99},
        {"id":22,"soldierName": "严鑫全","flowerNumber":99},
        {"id": 23,"soldierName": "束维流","flowerNumber":99},
        {"id": 24,"soldierName": "易永泉","flowerNumber":99},
        {"id": 25,"soldierName": "商良银","flowerNumber":99},
        {"id": 26,"soldierName": "易永泉","flowerNumber":99},
        {"id": 27,"soldierName": "商良银","flowerNumber":99},
        {"id": 28,"soldierName": "束维流","flowerNumber":99},
        {"id": 29,"soldierName": "易永泉","flowerNumber":99},
        {"id": 30,"soldierName": "商良银","flowerNumber":99},
        {"id": 31,"soldierName": "易永泉","flowerNumber":99},
        {"id": 32,"soldierName": "商良银","flowerNumber":99},
        {"id": 33,"soldierName": "束维流","flowerNumber":99},
        {"id": 34,"soldierName": "易永泉","flowerNumber":99},
        {"id": 35,"soldierName": "商良银","flowerNumber":99},
        {"id": 36,"soldierName": "易永泉","flowerNumber":99},
        {"id": 37,"soldierName": "商良银","flowerNumber":99},
        {"id": 38,"soldierName": "束维流","flowerNumber":99},
        {"id": 39,"soldierName": "易永泉","flowerNumber":99},
        {"id": 40,"soldierName": "商良银","flowerNumber":99},
        {"id": 41,"soldierName": "易永泉","flowerNumber":99},
        {"id": 42,"soldierName": "商良银","flowerNumber":99},
        {"id": 43,"soldierName": "束维流","flowerNumber":99},
        {"id": 44,"soldierName": "易永泉","flowerNumber":99},
        {"id": 45,"soldierName": "商良银","flowerNumber":99},
        {"id": 46,"soldierName": "易永泉","flowerNumber":99},
        {"id": 47,"soldierName": "商良银","flowerNumber":99},
        {"id": 38,"soldierName": "束维流","flowerNumber":99},
        {"id": 49,"soldierName": "易永泉","flowerNumber":99},
        {"id": 50,"soldierName": "商良银","flowerNumber":99},
        {"id": 51,"soldierName": "易永泉","flowerNumber":99},
        {"id": 52,"soldierName": "商良银","flowerNumber":99},
        {"id": 53,"soldierName": "束维流","flowerNumber":99},
        {"id": 54,"soldierName": "易永泉","flowerNumber":99},
        {"id": 55,"soldierName": "商良银","flowerNumber":99},
        {"id": 56,"soldierName": "易永泉","flowerNumber":99},
        {"id": 57,"soldierName": "商良银","flowerNumber":99}
