<template>
    <div class="container">
      <span class="demonstration"></span>
      <el-carousel height="576px" >
        <el-carousel-item v-for="item in items" :key="item.id" >
          <img :src="item.url" alt="轮播图片" @click="showArticle(item.targetId,item.type)">
          <div class="overlay">
            <span class="title" @click="showArticle(item.targetId,item.type)" >{{ item.title }}</span>
            <el-divider></el-divider>
            <span class="introduce"></span>
         </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </template>
  <script>
  import service from '../axios/axios'
  export default {
    data() {
      return {
        code:'',
        items:[
          {photoId:1,articleTd:1,url:require("../img/烈士信息背景图.jpeg"),title:'文章标题',alt:"图片"},
        ],
        message:'',
        total:1
      };
    },
    methods: {
      showArticle(id,type){
        console.log(id,type)
        this.$router.push({path:'/ArticleDetail',query:{articleId:id,type:type}});
      },
      getData(){
          let count=0;
        service.get('/article/carousel',{
          params:{
            number: 6
          }
        })
          .then(response => {
              this.items=[]
              for(let i=0;i<Number(response.data.data.total);i++){
                  let item={
                      id:count++,
                      type: 1,
                      targetId: Number(response.data.data.rows[i].targetId),
                      url: response.data.data.rows[i].url,
                      title:  response.data.data.rows[i].title,
                      alt:response.data.data.rows[i].alt
                  }
                  this.items.push(item)
              }
              // this.total=Number(response.data.data.total)
              // service.get('/relic/carousel',{
              //     params:{
              //         number: 4
              //     }
              // })
              //     .then(response => {
              //         for(let i=0;i<Number(response.data.data.total);i++){
              //             let item={
              //                 id:count++,
              //                 type: 0,
              //                 targetId: Number(response.data.data.rows[i].targetId),
              //                 url: response.data.data.rows[i].url,
              //                 title:  response.data.data.rows[i].title,
              //                 alt:response.data.data.rows[i].alt
              //             }
              //             this.items.push(item)
              //         }
              //         this.total+=Number(response.data.data.total)
              //         console.log(this.items)

              //     })
              //     .catch(error => {
              //         // this.$message.error('错了哦，这是一条错误消息');
              //     })

          })
          .catch(error => {
           // this.$message.error('错了哦，这是一条错误消息');
      })
      }
    },
    mounted(){
       this.getData();
    }
  }
</script>
  <style scoped>
  .container{
    width:100%;
  }
    img {
      width: 100%; /* 使图片宽度充满容器 */
      height: 100%; /* 使图片高度充满容器 */
      object-fit: 100% 100%;
      cursor: pointer;
    }
    .overlay {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background-color: rgba(0, 0, 0, 0.269);
    }

    .title {
        color: white;
        font-size: 50px;
        font-family: '华文行楷';
        position: absolute;
        top: 25%;
        left: 450px;
        transform: translate(-50%, -50%);
    }
    .title:hover {
        font-size: 52px;
    }
    .introduce{
        color: white;
        font-size: 21.6px;
        font-family: '楷体';
        position: absolute;
        top: 60%;
        left: 15%;
        transform: translate(-50%, -50%);
    }
    .introduce:hover{
        font-size: 23px;
    }
   div >>> .el-divider--horizontal{
      margin-top:75px;
      width: 65%;
   }

  </style>
