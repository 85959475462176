import Mock from 'mockjs';
Mock.mock('/product/search',{
    "ret":0,
    'list|30': [{  // 生成长度为30的数组
        'number|+1': 1,  // id 递增，起始值为1
        'question': '@csentence',  // 随机生成中文名字
        'A':'@csentence', 
        'B':'@csentence', 
        'C':'@csentence', 
        'D':'@csentence', 
        'E':'@csentence',
        'F':'@csentence',
        'answer': '@email',  // 随机生成18到60之间的整数
        'image':"@image('200x100','#ffcc33','FFF','png','Fast Mock')",
        'status':'info',
      }]
})
export default Mock;