<template>
    <div class="bottom-show">
        <a href="https://baike.baidu.com/item/%E8%8A%9C%E6%B9%96%E5%B8%82%E7%83%88%E5%A3%AB%E9%99%B5%E5%9B%AD/62862305?fr=ge_ala" style="text-decoration: none;">
        <span>百度百科</span>
        </a>
        <el-divider direction="vertical"></el-divider>
        <span>友情链接</span>
        <el-divider direction="vertical"></el-divider>
        <span>网站说明</span>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.bottom-show{
    width: 100%;
    height: 72px;
    background-color: #cb4949;
    display: flex;
    justify-content:space-around;
    align-items: center;
}
.bottom-show span{
    color: #fff;
}
</style>