<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in items" :key="index">
        <div class="item_person">
          <div><img src="@/img/祭扫.png" style="width: 100px;height:60px;"/></div>
          <div class="name" @click="goToxianhua(item)">{{ item.name }}</div>
          <div ><el-button @click="goToxianhua(item)">献花</el-button></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import service from '@/axios/axios';
export default {
  data() {
    return {
      // 模拟数据，每个card的内容
      items:
      [{id:300,name:'曹环飞'},
       {id:301,name:'曹圣义'},
       {id:302,name:'陈胜春'},
       {id:313,name:'陈兆树'},
       {id:287,name:'崔祥'},
       {id:309,name:'杜进'},
       {id:293,name:'宦守钊'},
       {id:310,name:'姜慧中'},
       {id:312,name:'李和堂'},
       {id:288,name:'李良忠'},
       {id:298,name:'李荣华'},
       {id:304,name:'李英杰'},
       {id:295,name:'彭锐峰'},
       {id:290,name:'任智武'},
       {id:308,name:'万海运'},
       {id:305,name:'王汉臣'},
       {id:317,name:'无名'},
       {id:292,name:'吴传本'},
       {id:307,name:'吴华钧'},
       {id:294,name:'肖平'},
       {id:289,name:'谢曙钦'},
       {id:306,name:'徐金亭'},
       {id:291,name:'俞力行'},
       {id:297,name:'张冬云'},
       {id:311,name:'张海余'},
       {id:303,name:'张磊'},
       {id:296,name:'张延长'},
       {id:299,name:'张元义'},]
    };
  },
  methods: {
    goToxianhua(item){
      service.get('/local/dedicate',{
                params: {
                        id:item.id
                }
            })
            .then(res=>{
              const url = `https://js.aladn.cn/bendi_files/Web/${item.name}烈士/index.html`;
              window.open(url, '_blank');
          })
    }
  }
};
</script>

<style scoped>
.container {
  width: 1440px;
  height: auto;
}

/* 调整卡片间距 */
.el-row {
  margin-bottom: 20px;
}

.item_person{
  margin: 20px;
  padding-right:15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(219, 170, 9, 0.92);
}
.item_person:hover{
  background-color:#e0c02d;
}
.name{
  font-size: 20px;
  font-family: '楷体';
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.name:hover{
  color:#c6332b
}
.el-button{
  background: #c6332b;
  font-family: '楷体';
  font-size:18px;
  color:#fff;
  /* margin-left: 30px; */
}
.el-button:hover{
  background: #b42e27;
  color:#ebd13b;
  /* margin-left: 30px; */
}
</style>