
<template>
<div class="body">
  <div style="width:1440px;height:400px;"><img src="./img/主页图片.png"  style="width:1440px;height:400px;"></div>
  <TopMenu style="width:1440px"></TopMenu>
  <Notification></Notification>
  <div id="app">
    <router-view></router-view>
  </div>
</div>
</template>
<script>
import TopMenu from './components/TopMenu.vue';
import WebFirstPage from './pages/WebFirstPage.vue';
import InformationPage from './pages/InformationPage.vue';
import ArticleDetail from './pages/ArticleDetail.vue';
import Notification from './components/notiFication.vue'
export default {
  name: 'App',
  components: {
    TopMenu,
    WebFirstPage,
    InformationPage,
    ArticleDetail,
    Notification
  },
  data() {
    return {

    }
  },

  created() {
  },

}
</script>

<style scoped>
.body{
  min-height:100vh;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-image: url('./img/整体背景图片.jpg');
  background-size: 100% 100%;
  z-index: 9999;
}
#app{
  max-width: 1440px;
  background-color: white; /* 设置 #app 的背景色 */
  z-index: 5; /* 确保 #app 处于最顶层 */
  position: relative; /* 设置为相对定位，以便 z-index 生效 */
  /* background-image: url('./img/展示板.jpeg'); */
  background-size: 100% 100%;
  margin-bottom:20px;
}
</style>
