<template>
    <div class="contain">
        <div style="margin-bottom:50px;margin-top:70px;">
             <el-divider content-position="center">烈士风采</el-divider>
        </div>
        <div class="martyr-show"> 
            <div>  
            <div >
                <div class="centerShow">
                    <el-carousel :interval="3000" type="card" show-indicators>
                        <el-carousel-item v-for="item in items" :key="item.id">  
                            <div class="container" @click="xianhua(item)">
                                <span v-if="item.type === '0'" class="showSpan">本地馆</span>
                                <span v-if="item.type === '1'" class="showSpan">异地馆</span>
                                <div class="component">
                                    <div class="Message">
                                        <div class="Message-left">
                                            <p>{{ item.soldierName  }}</p>
                                            <el-button @click="xianhua(item)">为ta献花</el-button>
                                        </div>
                                        <div class="Message-right">
                                            <el-tabs >
                                                <el-tab-pane label="基本信息">
                                                    <div style="display:flex">
                                                        <div style="display:flex;flex-direction: column;">
                                                            <span>性别：{{ item.sex }}</span>
                                                            <span>年龄：{{ item.age }}</span>
                                                            <span>籍贯：{{ item.nativePlace }}</span>
                                                        </div>
                                                        <div style="display:flex;flex-direction: column;">
                                                            <span>入伍时间：{{ item.joinTime }}</span>
                                                            <span>生前单位：{{ item.troop }}</span>
                                                            <span>身份：{{ item.job }}</span>
                                                        </div>
                                                    </div>
                                                </el-tab-pane>
                                            </el-tabs>
                                            <el-tabs>
                                                <el-tab-pane label="烈士事迹"><span>
                                                    {{ item.achievement }}
                                                </span>
                                                </el-tab-pane>
                                            </el-tabs>
                                        </div>
                                    </div>
                                    <div> 
                                    </div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div>
                    <span class="more" @click="viewMore()">>>>查看更多</span>
                </div>
            </div>
            </div>
        </div>
</div>
</template>

<script>
import service from '../axios/axios'
export default {
    data() {
      return {
        code:'',
        imagePath: require('../img/烈士信息背景图.jpeg'),
        items: [
          { id: 1, type:"1", solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 2, type:0, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 3, type:1, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 4, type:0, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 5, type:1, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 6, type:0, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 7, type:1, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 8, type:0, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
          { id: 9, type:1, solidier:4974,soldierName: '俞力行',flowerNumber:99,sex:'男',age:22,nativePlace:'南陵县',troop:'解放军6835部队',job:'战士',joinTime:'1965年入伍',achievement:'1965年7月21日在中越边界牺牲'},
        ],
        message:''
      };
  },
  methods:{
    xianhua(item) {
    this.$router.push({ name: 'xianhuaVenue', params: { id:item.id,heroname:item.soldierName } });
    },
    viewMore(){
        this.$router.push('/Local');
        this.$bus.$emit('activeIndex','/Local');
    },
    getData(){
        service.get('/soldierDetail/carousel',{
                    params: {
                        number:20
                    }
                })
          .then(response => {
            console.log("烈士风采");
            console.log(response.data);
            this.code=response.data.code;
            this.items=response.data.data.rows;
            this.total=parseInt(response.data.data.total, 10);
            this.message=response.data.message;
            // if(this.code===200){
            //     this.$message({
            //         message: this.message,
            //         type: 'success'
            //     });
            // }
            // else{
            //     this.$message.error(this.message);
            // }
          })
          .catch(error => {
            this.$message.error('错了哦，这是一条错误消息');
      })
    }
  },
  computed:{
    isVisible1(val) {
      return val===0;
    },
    isVisible2() {
      return val===1;
    }
  },
  mounted(){
    this.getData();
  }
}
</script>

<style scoped>
.martyr-show{
    margin:0 85px;

}
p{
    font-family: '楷体';
    color:black;
    font-size: 23px;
    font-weight: bolder;
    margin-bottom:14px;
    
}
p:hover{
    font-size: 24px;
    color:rgb(234, 193, 12);
}
span{
    font-family: '楷体';
    color:black;
    font-size: 17px;
    font-weight: bolder;
    margin-bottom:14.4px;
    margin-right:43.2px;

}
.Message{
    display: flex;
    
  
}
.Message-left{
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin:14.4px;
}
.container {
    position: relative; 
    width: 630px; 
    height: 360px; 
    background-image: url('../img/烈士信息背景图.jpeg'); /* 设置背景图片 */
    background-size: 100% 100%; /* 背景图片铺满容器 */
}
.component {
    position: absolute; 
    top:60px; 
    left: 70px; 
    width: 540px; 
    height: 259px; 
    background-color: rgba(255, 255, 255, 0.8); /* 设置组件背景颜色，可选 */
   
}
div >>>.el-carousel__indicators {
    margin-top: 80px;
}

div >>>.el-carousel__indicators button {
  background-color: #ae1111; /* 指示器未激活时的颜色 */
  width: 15px; /* 指示器的宽度 */
  height: 15px; /* 指示器的高度 */
  border-radius: 50%; /* 让指示器呈现圆形 */
  margin: 0 5px; /* 指示器之间的间距 */
  border: none;
}
div >>>.el-carousel--horizontal{
    height:auto;
    width:1280px;
}
div >>>.el-carousel__indicators button.is-active {
  background-color: #b93b3b; /* 指示器激活时的颜色 */
}
.centerShow{
    margin-top:43.2px;
    margin-bottom:43.2px;
}
.el-carousel-item{
    width: 640px; 
    height: 360px; 
    overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 图片充满容器 */
}
  .el-carousel__item:nth-child(2n) {
    width: 640px; 
    height: 360px; 
    overflow: hidden;
  }
  
  .el-carousel__item:nth-child(2n+1) {
   
    width: 640px; 
    height: 360px;  
    overflow: hidden;
  }
ul{
    list-style: none;
    margin-top:72px;
}
.more{
    cursor: pointer;
    color:brown;
    font-size: 28.8px;
    font-family: '宋体';
    font-weight: bolder;
    margin:72px;
    margin-left: 75%;
}
.more:hover{
    font-size: 28.8px;
}
div >>>.el-divider__text{
  font-size: 46px; /* 设置字体大小 */
  font-family: "华文行楷";
  font-weight: bold; /* 设置字体粗细 */
  color: rgb(96, 19, 19); /* 设置字体颜色 */
}
.martyr-grid {
  display: grid;
  grid-template-columns: repeat(4, 0.5fr);
  grid-gap: 20px;
}
.item-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.top-right{
    display: flex;
    flex-direction: column;
    margin-top:36px;
}
.top-right span{
    color:rgb(242, 188, 10);
    margin-top:28.8px;
    font-size: 18px;
    font-family: '楷体';
    font-weight: bolder;
}
.item-bottom{
    margin-top:28.8px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.el-button{
    cursor: pointer;
    width: 86px;
    background-color: rgb(206, 69, 42);
    color:rgb(255, 255, 255);
    border-radius: 10px;
    font-family: '楷体';
    font-weight: bolder;
 
}
.el-button:hover{
    background-color: rgb(159, 76, 40);
    color:rgb(255, 255, 255);
}
div >>>.el-tabs__item {
  color: black;
  font-size:21.6px;
  font-family:'楷体';
  font-weight:bolder;
  height:40px; 
}
div >>>.el-tabs__item.is-active{
  color: #9e2a22; /* 设置点击后的字体颜色 */
  font-size:21.6px;
}
div >>>.el-tabs__active-bar{
    height:5px;
    width:40px;
    background-color:#9e2a22;
}
.showSpan{
    margin-left:28.8px;
    font-family:'华文行楷';
    font-size:28.8px;
    color:#fff;
    background-color: #f41111;
    border-radius: 5%;
}
</style>