<template>
  <div class="outside">
    <div class="container">
      <div>
        <topLine ></topLine>
      </div>
      <div>
          <el-menu
          :default-active="activeIndex"
          class="top"
          mode="horizontal"
          :router="true"
          @select="handleSelect"
          text-color="black"
          active-text-color="brown">
          <el-menu-item index="/Information/news">新闻</el-menu-item>
          <el-menu-item index="/Information/activity">活动</el-menu-item>
      </el-menu>
            <router-view></router-view>
      </div>
  </div>
</div>
</template>

<script>
import topLine from '@/components/topLine.vue';
export default {
  components:{
    topLine
  },
data(){
    return{
        activeIndex:'',
        title:''
    }
},
methods:{
    handleSelect(key, keyPath) {
            this.activeIndex=key;
      },
    changeIndex(val){
        this.activeIndex = val;
    }     
},
mounted(){
   const index = this.$route.path;
    // 更新 activeIndex 的值
    this.activeIndex = index;
    this.$bus.$emit('activeIndex',index);
    this.$bus.$on('TopMenuIndex',this.changeIndex);
}
}
</script>
<style scoped>
  .outside{
    width:1440px;
    
  }
    .container{
      margin:0 20px;
     
    }
     .top{
        font-family: '隶书';
        font-weight: bolder;
    }
    .top .el-menu-item {
        font-size: 28.8px;
        margin-right: 43.2px;
        margin-top:14.4px;
    }
     .top .el-menu-item:hover{
      color: #b73d36;
  }

</style>