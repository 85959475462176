import { render, staticRenderFns } from "./MartyrShow.vue?vue&type=template&id=925ac0be&scoped=true"
import script from "./MartyrShow.vue?vue&type=script&lang=js"
export * from "./MartyrShow.vue?vue&type=script&lang=js"
import style0 from "./MartyrShow.vue?vue&type=style&index=0&id=925ac0be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "925ac0be",
  null
  
)

export default component.exports