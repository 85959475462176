<template>
    <div class="outside">
        <div class="container">
            <div>
            <div class="top">
                    <img src="../img/动态.png" class="top-img">
                    <span>置顶新闻</span>
            </div>
            <div class="topNews">
                <div v-show="showN">
                    <h3>暂无置顶新闻</h3>
                </div>
                <ul >
                        <li v-for="item in topNews" :key="item.id" @click="showArticle(item.id)">
                            <div class="top-item">
                                    <div class="item-left" :style="{ 'background-image': 'url(' + item.thumbnail + ')' }">
                                    </div>
                                    <div class="item-right">
                                        <el-tooltip :content="item.title" placement="top-start" effect="light">
                                            <div class="item-title">{{ item.title }}</div>
                                        </el-tooltip>
                                        <div class="item-date">{{item.updateTime}}</div>
                                    </div>
                            </div>
                        </li>
                </ul>
            </div>
            </div>
            <div>
                <div class="top">
                    <img src="../img/置顶.png" class="top-img">
                    <span>置顶活动</span>
                </div>
                <div class="topActivity">
                    <div v-show="showA">
                        <h3>暂无置顶活动</h3>
                    </div>
                    <ul >
                            <li v-for="item in topActivitys" :key="item.id" @click="showArticle(item.id)">
                                <div class="top-item">
                                        <div class="item-left" :style="{ 'background-image': 'url(' + item.thumbnail + ')' }">

                                        </div>
                                        <div class="item-right">
                                            <el-tooltip :content="item.title" placement="top-start" effect="light">
                                                <div class="item-title">{{item.title}}</div>
                                            </el-tooltip>
                                            <div class="item-date">{{item.updateTime}}</div>
                                        </div>
                                </div>
                            </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import service from '../axios/axios'
export default {
    data(){
        return{
        topActivitys: [
          { id: 1, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 2, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 3, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 4, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 5, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 6, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 7, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 8, top:1,title: '活动名称',thumbnail:require("../img/烈士信息背景图.jpeg"),summary:"内容摘要",updateTime:"2022-4-15"}
        ],
        topNews: [

        ],
        totalNews:0,
        totalActivity:6,
        message:'',
        code:'',
        showA:false,
        showN:false
        }
    },
    methods:{
        getActivityTopList(){
            service.get('/activity/TopList')
                .then(response => {
                    this.topActivitys=[];
                    this.totalActivity=Number(response.data.data.total);
                    this.topActivitys=[]
                    for(let i=0;i<response.data.data.total;i++){
                        let item={
                            id:Number(response.data.data.rows[i].id),
                            summary:response.data.data.rows[i].summary,
                            thumbnail:response.data.data.rows[i].thumbnail,
                            title:response.data.data.rows[i].title,
                            updateTime:response.data.data.rows[i].updateTime
                        }
                        this.topActivitys.push(item)
                    }
                    if(this.totalActivity===0)this.showA=true;
                })
                .catch(error => {
                    this.$message.error('错了哦，这是一条错误消息');
            })
        },
        getNewsTopList(){
            service.get('/news/TopList')
                .then(response => {
                    this.topNews=[];
                    this.totalNews=Number(response.data.data.total);
                    for(let i=0;i<response.data.data.total;i++){
                        let item={
                            id:Number(response.data.data.rows[i].id),
                            summary:response.data.data.rows[i].summary,
                            thumbnail:response.data.data.rows[i].thumbnail,
                            title:response.data.data.rows[i].title,
                            updateTime:response.data.data.rows[i].updateTime
                        }
                        this.topNews.push(item)
                    }
                    if(this.totalNews==0)this.showN=true;
                })
                .catch(error => {
                    this.$message.error('错了哦，这是一条错误消息');
            })
        },
        showArticle(val){
            this.$router.push({path:'/ArticleDetail',query:{articleId:val,type:1}});
    },
    },
    mounted(){
        this.getActivityTopList();
        this.getNewsTopList();
        // this.showN=true
    }
}
</script>

<style scoped>
ul{
    list-style-type: none;
    padding-inline-start: 0px;
    overflow: hidden;

}
@keyframes scroll-vertical {
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
}
li {
    display: block; /* 使列表项垂直排列 */
    animation: scroll-vertical 5s linear infinite; /* 应用垂直滚动动画 */
}
.top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}
.top span{
    font-family: '黑体';
    color: #d41212;
    font-size: 28.8px;
    margin-top:14.4px;
    margin-left:14.4px;
}
.top-img{
    width: 43.2px;
    height: 43.2px;
    margin-top:14.4px;
    margin-left:14.4px;
    margin-bottom:1.44px;
}
.topNews {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.topNews::-webkit-scrollbar {
    display: none;
}
.topActivity {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.topActivity::-webkit-scrollbar {
    display: none;
}
.topNews{
    width:615px;
    height:144px;
    border-radius: 5px;
    border: 2px solid brown;

}
.topActivity{
    width:615px;
    height: 144px; /* 设置最大高度 */
    border: 2px solid brown;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.item-left{
    width: 115px;
    height: 64.8px;
    background-size: 100% 100%;
}
.top-item{
    /* width:615px; */
    height: 64.8px;
    cursor: pointer;
    margin-left: 0;
    display:flex;
    flex-direction: row;
    background-color: rgb(215, 219, 223);
    margin-bottom:14.4px;

}
.top-item:hover{
    background-color:rgb(238, 157, 133);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.item-right{
    display:flex;
    flex-direction: column;
    margin:10px;
}
.item-date{
   margin-top:10px ;
   font-size: 10px;
}
/* .item-date{
    font-size: 10px;
} */
.container {
    margin:0 50px;
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 60px;
    background-size: 100% 100%;
}
.item-title{
    width:450px;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden;   /* 隐藏超出的内容 */
    text-overflow: ellipsis; /* 超出的部分用省略号表示 */
}
</style>
{ id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"},
          { id: 1, title: '新闻名称',thumbnail:"",summary:"内容摘要",updateTime:"2022-4-15"}
